@charset "UTF-8";
@import url("https://fonts.googleapis.com/css2?family=Lato&display=swap");
@font-face {
  font-family: 'Yu Gothic';
  src: local("Yu Gothic Medium");
  font-weight: 100; }

@font-face {
  font-family: 'Yu Gothic';
  src: local("Yu Gothic Medium");
  font-weight: 200; }

@font-face {
  font-family: 'Yu Gothic';
  src: local("Yu Gothic Medium");
  font-weight: 300; }

@font-face {
  font-family: 'Yu Gothic';
  src: local("Yu Gothic Medium");
  font-weight: 400; }

@font-face {
  font-family: 'Yu Gothic';
  src: local("Yu Gothic Bold");
  font-weight: bold; }

/*
	HTML5 Reset :: style.css
	----------------------------------------------------------
	We have learned much from/been inspired by/taken code where offered from:

	Eric Meyer					:: http://meyerweb.com
	HTML5 Doctor				:: http://html5doctor.com
	and the HTML5 Boilerplate	:: http://html5boilerplate.com

-------------------------------------------------------------------------------*/
/* Let's default this puppy out
-------------------------------------------------------------------------------*/
html, body, body div, span, object, iframe, h1, h2, h3, h4, h5, h6, p, blockquote, pre, abbr, address, cite, code, del, dfn, em, img, ins, kbd, q, samp, small, strong, sub, sup, var, b, i, dl, dt, dd, ol, ul, li, fieldset, form, label, legend, table, caption, tbody, tfoot, thead, tr, th, td, article, aside, figure, footer, header, menu, nav, section, time, mark, audio, video, details, summary {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font-weight: normal;
  vertical-align: baseline;
  background: transparent; }

article, aside, figure, footer, header, nav, section, details, summary {
  display: block; }

/* Handle box-sizing while better addressing child elements:
   http://css-tricks.com/inheriting-box-sizing-probably-slightly-better-best-practice/ */
html {
  box-sizing: border-box; }

*,
*:before,
*:after {
  box-sizing: inherit; }

/* consider resetting the default cursor: https://gist.github.com/murtaugh/5247154 */
/* Responsive images and other embedded objects */
/* if you don't have full control over `img` tags (if you have to overcome attributes), consider adding height: auto */
img,
object,
embed {
  max-width: 100%; }

/*
   Note: keeping IMG here will cause problems if you're using foreground images as sprites.
	In fact, it *will* cause problems with Google Maps' controls at small size.
	If this is the case for you, try uncommenting the following:

#map img {
		max-width: none;
}
*/
/* force a vertical scrollbar to prevent a jumpy page */
html {
  overflow-y: scroll; }

/* we use a lot of ULs that aren't bulleted.
	you'll have to restore the bullets within content,
	which is fine because they're probably customized anyway */
ul {
  list-style: none; }

blockquote, q {
  quotes: none; }

blockquote:before,
blockquote:after,
q:before,
q:after {
  content: '';
  content: none; }

a {
  margin: 0;
  padding: 0;
  font-size: 100%;
  vertical-align: baseline;
  background: transparent; }

del {
  text-decoration: line-through; }

abbr[title], dfn[title] {
  border-bottom: 1px dotted #000;
  cursor: help; }

/* tables still need cellspacing="0" in the markup */
table {
  border-collapse: collapse;
  border-spacing: 0; }

th {
  font-weight: bold;
  vertical-align: bottom; }

td {
  font-weight: normal;
  vertical-align: top; }

hr {
  display: block;
  height: 1px;
  border: 0;
  border-top: 1px solid #ccc;
  margin: 1em 0;
  padding: 0; }

input, select {
  vertical-align: middle; }

pre {
  white-space: pre;
  /* CSS2 */
  white-space: pre-wrap;
  /* CSS 2.1 */
  white-space: pre-line;
  /* CSS 3 (and 2.1 as well, actually) */
  word-wrap: break-word;
  /* IE */ }

input[type="radio"] {
  vertical-align: text-bottom; }

input[type="checkbox"] {
  vertical-align: bottom; }

.ie7 input[type="checkbox"] {
  vertical-align: baseline; }

.ie6 input {
  vertical-align: text-bottom; }

select, input, textarea {
  font: 99% sans-serif; }

table {
  font-size: inherit;
  font: 100%; }

small {
  font-size: 85%; }

strong {
  font-weight: bold; }

td, td img {
  vertical-align: top; }

/* Make sure sup and sub don't mess with your line-heights http://gist.github.com/413930 */
sub, sup {
  font-size: 75%;
  line-height: 0;
  position: relative; }

sup {
  top: -0.5em; }

sub {
  bottom: -0.25em; }

/* standardize any monospaced elements */
pre, code, kbd, samp {
  font-family: monospace, sans-serif; }

/* hand cursor on clickable elements */
.clickable,
label,
input[type=button],
input[type=submit],
input[type=file],
button {
  cursor: pointer; }

/* Webkit browsers add a 2px margin outside the chrome of form elements */
button, input, select, textarea {
  margin: 0; }

/* make buttons play nice in IE */
button,
input[type=button] {
  width: auto;
  overflow: visible; }

/* scale images in IE7 more attractively */
.ie7 img {
  -ms-interpolation-mode: bicubic; }

/* prevent BG image flicker upon hover
   (commented out as usage is rare, and the filter syntax messes with some pre-processors)
.ie6 html {filter: expression(document.execCommand("BackgroundImageCache", false, true));}
*/
/* let's clear some floats */
.clearfix:before, .clearfix:after {
  content: "\0020";
  display: block;
  height: 0;
  overflow: hidden; }

.clearfix:after {
  clear: both; }

.clearfix {
  zoom: 1; }

body[data-aos-duration='50'] [data-aos], [data-aos][data-aos][data-aos-duration='50'] {
  transition-duration: 50ms; }

body[data-aos-delay='50'] [data-aos], [data-aos][data-aos][data-aos-delay='50'] {
  transition-delay: 0; }
  body[data-aos-delay='50'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='50'].aos-animate {
    transition-delay: 50ms; }

body[data-aos-duration='100'] [data-aos], [data-aos][data-aos][data-aos-duration='100'] {
  transition-duration: 100ms; }

body[data-aos-delay='100'] [data-aos], [data-aos][data-aos][data-aos-delay='100'] {
  transition-delay: 0; }
  body[data-aos-delay='100'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='100'].aos-animate {
    transition-delay: 100ms; }

body[data-aos-duration='150'] [data-aos], [data-aos][data-aos][data-aos-duration='150'] {
  transition-duration: 150ms; }

body[data-aos-delay='150'] [data-aos], [data-aos][data-aos][data-aos-delay='150'] {
  transition-delay: 0; }
  body[data-aos-delay='150'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='150'].aos-animate {
    transition-delay: 150ms; }

body[data-aos-duration='200'] [data-aos], [data-aos][data-aos][data-aos-duration='200'] {
  transition-duration: 200ms; }

body[data-aos-delay='200'] [data-aos], [data-aos][data-aos][data-aos-delay='200'] {
  transition-delay: 0; }
  body[data-aos-delay='200'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='200'].aos-animate {
    transition-delay: 200ms; }

body[data-aos-duration='250'] [data-aos], [data-aos][data-aos][data-aos-duration='250'] {
  transition-duration: 250ms; }

body[data-aos-delay='250'] [data-aos], [data-aos][data-aos][data-aos-delay='250'] {
  transition-delay: 0; }
  body[data-aos-delay='250'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='250'].aos-animate {
    transition-delay: 250ms; }

body[data-aos-duration='300'] [data-aos], [data-aos][data-aos][data-aos-duration='300'] {
  transition-duration: 300ms; }

body[data-aos-delay='300'] [data-aos], [data-aos][data-aos][data-aos-delay='300'] {
  transition-delay: 0; }
  body[data-aos-delay='300'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='300'].aos-animate {
    transition-delay: 300ms; }

body[data-aos-duration='350'] [data-aos], [data-aos][data-aos][data-aos-duration='350'] {
  transition-duration: 350ms; }

body[data-aos-delay='350'] [data-aos], [data-aos][data-aos][data-aos-delay='350'] {
  transition-delay: 0; }
  body[data-aos-delay='350'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='350'].aos-animate {
    transition-delay: 350ms; }

body[data-aos-duration='400'] [data-aos], [data-aos][data-aos][data-aos-duration='400'] {
  transition-duration: 400ms; }

body[data-aos-delay='400'] [data-aos], [data-aos][data-aos][data-aos-delay='400'] {
  transition-delay: 0; }
  body[data-aos-delay='400'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='400'].aos-animate {
    transition-delay: 400ms; }

body[data-aos-duration='450'] [data-aos], [data-aos][data-aos][data-aos-duration='450'] {
  transition-duration: 450ms; }

body[data-aos-delay='450'] [data-aos], [data-aos][data-aos][data-aos-delay='450'] {
  transition-delay: 0; }
  body[data-aos-delay='450'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='450'].aos-animate {
    transition-delay: 450ms; }

body[data-aos-duration='500'] [data-aos], [data-aos][data-aos][data-aos-duration='500'] {
  transition-duration: 500ms; }

body[data-aos-delay='500'] [data-aos], [data-aos][data-aos][data-aos-delay='500'] {
  transition-delay: 0; }
  body[data-aos-delay='500'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='500'].aos-animate {
    transition-delay: 500ms; }

body[data-aos-duration='550'] [data-aos], [data-aos][data-aos][data-aos-duration='550'] {
  transition-duration: 550ms; }

body[data-aos-delay='550'] [data-aos], [data-aos][data-aos][data-aos-delay='550'] {
  transition-delay: 0; }
  body[data-aos-delay='550'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='550'].aos-animate {
    transition-delay: 550ms; }

body[data-aos-duration='600'] [data-aos], [data-aos][data-aos][data-aos-duration='600'] {
  transition-duration: 600ms; }

body[data-aos-delay='600'] [data-aos], [data-aos][data-aos][data-aos-delay='600'] {
  transition-delay: 0; }
  body[data-aos-delay='600'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='600'].aos-animate {
    transition-delay: 600ms; }

body[data-aos-duration='650'] [data-aos], [data-aos][data-aos][data-aos-duration='650'] {
  transition-duration: 650ms; }

body[data-aos-delay='650'] [data-aos], [data-aos][data-aos][data-aos-delay='650'] {
  transition-delay: 0; }
  body[data-aos-delay='650'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='650'].aos-animate {
    transition-delay: 650ms; }

body[data-aos-duration='700'] [data-aos], [data-aos][data-aos][data-aos-duration='700'] {
  transition-duration: 700ms; }

body[data-aos-delay='700'] [data-aos], [data-aos][data-aos][data-aos-delay='700'] {
  transition-delay: 0; }
  body[data-aos-delay='700'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='700'].aos-animate {
    transition-delay: 700ms; }

body[data-aos-duration='750'] [data-aos], [data-aos][data-aos][data-aos-duration='750'] {
  transition-duration: 750ms; }

body[data-aos-delay='750'] [data-aos], [data-aos][data-aos][data-aos-delay='750'] {
  transition-delay: 0; }
  body[data-aos-delay='750'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='750'].aos-animate {
    transition-delay: 750ms; }

body[data-aos-duration='800'] [data-aos], [data-aos][data-aos][data-aos-duration='800'] {
  transition-duration: 800ms; }

body[data-aos-delay='800'] [data-aos], [data-aos][data-aos][data-aos-delay='800'] {
  transition-delay: 0; }
  body[data-aos-delay='800'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='800'].aos-animate {
    transition-delay: 800ms; }

body[data-aos-duration='850'] [data-aos], [data-aos][data-aos][data-aos-duration='850'] {
  transition-duration: 850ms; }

body[data-aos-delay='850'] [data-aos], [data-aos][data-aos][data-aos-delay='850'] {
  transition-delay: 0; }
  body[data-aos-delay='850'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='850'].aos-animate {
    transition-delay: 850ms; }

body[data-aos-duration='900'] [data-aos], [data-aos][data-aos][data-aos-duration='900'] {
  transition-duration: 900ms; }

body[data-aos-delay='900'] [data-aos], [data-aos][data-aos][data-aos-delay='900'] {
  transition-delay: 0; }
  body[data-aos-delay='900'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='900'].aos-animate {
    transition-delay: 900ms; }

body[data-aos-duration='950'] [data-aos], [data-aos][data-aos][data-aos-duration='950'] {
  transition-duration: 950ms; }

body[data-aos-delay='950'] [data-aos], [data-aos][data-aos][data-aos-delay='950'] {
  transition-delay: 0; }
  body[data-aos-delay='950'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='950'].aos-animate {
    transition-delay: 950ms; }

body[data-aos-duration='1000'] [data-aos], [data-aos][data-aos][data-aos-duration='1000'] {
  transition-duration: 1000ms; }

body[data-aos-delay='1000'] [data-aos], [data-aos][data-aos][data-aos-delay='1000'] {
  transition-delay: 0; }
  body[data-aos-delay='1000'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='1000'].aos-animate {
    transition-delay: 1000ms; }

body[data-aos-duration='1050'] [data-aos], [data-aos][data-aos][data-aos-duration='1050'] {
  transition-duration: 1050ms; }

body[data-aos-delay='1050'] [data-aos], [data-aos][data-aos][data-aos-delay='1050'] {
  transition-delay: 0; }
  body[data-aos-delay='1050'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='1050'].aos-animate {
    transition-delay: 1050ms; }

body[data-aos-duration='1100'] [data-aos], [data-aos][data-aos][data-aos-duration='1100'] {
  transition-duration: 1100ms; }

body[data-aos-delay='1100'] [data-aos], [data-aos][data-aos][data-aos-delay='1100'] {
  transition-delay: 0; }
  body[data-aos-delay='1100'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='1100'].aos-animate {
    transition-delay: 1100ms; }

body[data-aos-duration='1150'] [data-aos], [data-aos][data-aos][data-aos-duration='1150'] {
  transition-duration: 1150ms; }

body[data-aos-delay='1150'] [data-aos], [data-aos][data-aos][data-aos-delay='1150'] {
  transition-delay: 0; }
  body[data-aos-delay='1150'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='1150'].aos-animate {
    transition-delay: 1150ms; }

body[data-aos-duration='1200'] [data-aos], [data-aos][data-aos][data-aos-duration='1200'] {
  transition-duration: 1200ms; }

body[data-aos-delay='1200'] [data-aos], [data-aos][data-aos][data-aos-delay='1200'] {
  transition-delay: 0; }
  body[data-aos-delay='1200'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='1200'].aos-animate {
    transition-delay: 1200ms; }

body[data-aos-duration='1250'] [data-aos], [data-aos][data-aos][data-aos-duration='1250'] {
  transition-duration: 1250ms; }

body[data-aos-delay='1250'] [data-aos], [data-aos][data-aos][data-aos-delay='1250'] {
  transition-delay: 0; }
  body[data-aos-delay='1250'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='1250'].aos-animate {
    transition-delay: 1250ms; }

body[data-aos-duration='1300'] [data-aos], [data-aos][data-aos][data-aos-duration='1300'] {
  transition-duration: 1300ms; }

body[data-aos-delay='1300'] [data-aos], [data-aos][data-aos][data-aos-delay='1300'] {
  transition-delay: 0; }
  body[data-aos-delay='1300'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='1300'].aos-animate {
    transition-delay: 1300ms; }

body[data-aos-duration='1350'] [data-aos], [data-aos][data-aos][data-aos-duration='1350'] {
  transition-duration: 1350ms; }

body[data-aos-delay='1350'] [data-aos], [data-aos][data-aos][data-aos-delay='1350'] {
  transition-delay: 0; }
  body[data-aos-delay='1350'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='1350'].aos-animate {
    transition-delay: 1350ms; }

body[data-aos-duration='1400'] [data-aos], [data-aos][data-aos][data-aos-duration='1400'] {
  transition-duration: 1400ms; }

body[data-aos-delay='1400'] [data-aos], [data-aos][data-aos][data-aos-delay='1400'] {
  transition-delay: 0; }
  body[data-aos-delay='1400'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='1400'].aos-animate {
    transition-delay: 1400ms; }

body[data-aos-duration='1450'] [data-aos], [data-aos][data-aos][data-aos-duration='1450'] {
  transition-duration: 1450ms; }

body[data-aos-delay='1450'] [data-aos], [data-aos][data-aos][data-aos-delay='1450'] {
  transition-delay: 0; }
  body[data-aos-delay='1450'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='1450'].aos-animate {
    transition-delay: 1450ms; }

body[data-aos-duration='1500'] [data-aos], [data-aos][data-aos][data-aos-duration='1500'] {
  transition-duration: 1500ms; }

body[data-aos-delay='1500'] [data-aos], [data-aos][data-aos][data-aos-delay='1500'] {
  transition-delay: 0; }
  body[data-aos-delay='1500'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='1500'].aos-animate {
    transition-delay: 1500ms; }

body[data-aos-duration='1550'] [data-aos], [data-aos][data-aos][data-aos-duration='1550'] {
  transition-duration: 1550ms; }

body[data-aos-delay='1550'] [data-aos], [data-aos][data-aos][data-aos-delay='1550'] {
  transition-delay: 0; }
  body[data-aos-delay='1550'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='1550'].aos-animate {
    transition-delay: 1550ms; }

body[data-aos-duration='1600'] [data-aos], [data-aos][data-aos][data-aos-duration='1600'] {
  transition-duration: 1600ms; }

body[data-aos-delay='1600'] [data-aos], [data-aos][data-aos][data-aos-delay='1600'] {
  transition-delay: 0; }
  body[data-aos-delay='1600'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='1600'].aos-animate {
    transition-delay: 1600ms; }

body[data-aos-duration='1650'] [data-aos], [data-aos][data-aos][data-aos-duration='1650'] {
  transition-duration: 1650ms; }

body[data-aos-delay='1650'] [data-aos], [data-aos][data-aos][data-aos-delay='1650'] {
  transition-delay: 0; }
  body[data-aos-delay='1650'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='1650'].aos-animate {
    transition-delay: 1650ms; }

body[data-aos-duration='1700'] [data-aos], [data-aos][data-aos][data-aos-duration='1700'] {
  transition-duration: 1700ms; }

body[data-aos-delay='1700'] [data-aos], [data-aos][data-aos][data-aos-delay='1700'] {
  transition-delay: 0; }
  body[data-aos-delay='1700'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='1700'].aos-animate {
    transition-delay: 1700ms; }

body[data-aos-duration='1750'] [data-aos], [data-aos][data-aos][data-aos-duration='1750'] {
  transition-duration: 1750ms; }

body[data-aos-delay='1750'] [data-aos], [data-aos][data-aos][data-aos-delay='1750'] {
  transition-delay: 0; }
  body[data-aos-delay='1750'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='1750'].aos-animate {
    transition-delay: 1750ms; }

body[data-aos-duration='1800'] [data-aos], [data-aos][data-aos][data-aos-duration='1800'] {
  transition-duration: 1800ms; }

body[data-aos-delay='1800'] [data-aos], [data-aos][data-aos][data-aos-delay='1800'] {
  transition-delay: 0; }
  body[data-aos-delay='1800'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='1800'].aos-animate {
    transition-delay: 1800ms; }

body[data-aos-duration='1850'] [data-aos], [data-aos][data-aos][data-aos-duration='1850'] {
  transition-duration: 1850ms; }

body[data-aos-delay='1850'] [data-aos], [data-aos][data-aos][data-aos-delay='1850'] {
  transition-delay: 0; }
  body[data-aos-delay='1850'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='1850'].aos-animate {
    transition-delay: 1850ms; }

body[data-aos-duration='1900'] [data-aos], [data-aos][data-aos][data-aos-duration='1900'] {
  transition-duration: 1900ms; }

body[data-aos-delay='1900'] [data-aos], [data-aos][data-aos][data-aos-delay='1900'] {
  transition-delay: 0; }
  body[data-aos-delay='1900'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='1900'].aos-animate {
    transition-delay: 1900ms; }

body[data-aos-duration='1950'] [data-aos], [data-aos][data-aos][data-aos-duration='1950'] {
  transition-duration: 1950ms; }

body[data-aos-delay='1950'] [data-aos], [data-aos][data-aos][data-aos-delay='1950'] {
  transition-delay: 0; }
  body[data-aos-delay='1950'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='1950'].aos-animate {
    transition-delay: 1950ms; }

body[data-aos-duration='2000'] [data-aos], [data-aos][data-aos][data-aos-duration='2000'] {
  transition-duration: 2000ms; }

body[data-aos-delay='2000'] [data-aos], [data-aos][data-aos][data-aos-delay='2000'] {
  transition-delay: 0; }
  body[data-aos-delay='2000'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='2000'].aos-animate {
    transition-delay: 2000ms; }

body[data-aos-duration='2050'] [data-aos], [data-aos][data-aos][data-aos-duration='2050'] {
  transition-duration: 2050ms; }

body[data-aos-delay='2050'] [data-aos], [data-aos][data-aos][data-aos-delay='2050'] {
  transition-delay: 0; }
  body[data-aos-delay='2050'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='2050'].aos-animate {
    transition-delay: 2050ms; }

body[data-aos-duration='2100'] [data-aos], [data-aos][data-aos][data-aos-duration='2100'] {
  transition-duration: 2100ms; }

body[data-aos-delay='2100'] [data-aos], [data-aos][data-aos][data-aos-delay='2100'] {
  transition-delay: 0; }
  body[data-aos-delay='2100'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='2100'].aos-animate {
    transition-delay: 2100ms; }

body[data-aos-duration='2150'] [data-aos], [data-aos][data-aos][data-aos-duration='2150'] {
  transition-duration: 2150ms; }

body[data-aos-delay='2150'] [data-aos], [data-aos][data-aos][data-aos-delay='2150'] {
  transition-delay: 0; }
  body[data-aos-delay='2150'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='2150'].aos-animate {
    transition-delay: 2150ms; }

body[data-aos-duration='2200'] [data-aos], [data-aos][data-aos][data-aos-duration='2200'] {
  transition-duration: 2200ms; }

body[data-aos-delay='2200'] [data-aos], [data-aos][data-aos][data-aos-delay='2200'] {
  transition-delay: 0; }
  body[data-aos-delay='2200'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='2200'].aos-animate {
    transition-delay: 2200ms; }

body[data-aos-duration='2250'] [data-aos], [data-aos][data-aos][data-aos-duration='2250'] {
  transition-duration: 2250ms; }

body[data-aos-delay='2250'] [data-aos], [data-aos][data-aos][data-aos-delay='2250'] {
  transition-delay: 0; }
  body[data-aos-delay='2250'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='2250'].aos-animate {
    transition-delay: 2250ms; }

body[data-aos-duration='2300'] [data-aos], [data-aos][data-aos][data-aos-duration='2300'] {
  transition-duration: 2300ms; }

body[data-aos-delay='2300'] [data-aos], [data-aos][data-aos][data-aos-delay='2300'] {
  transition-delay: 0; }
  body[data-aos-delay='2300'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='2300'].aos-animate {
    transition-delay: 2300ms; }

body[data-aos-duration='2350'] [data-aos], [data-aos][data-aos][data-aos-duration='2350'] {
  transition-duration: 2350ms; }

body[data-aos-delay='2350'] [data-aos], [data-aos][data-aos][data-aos-delay='2350'] {
  transition-delay: 0; }
  body[data-aos-delay='2350'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='2350'].aos-animate {
    transition-delay: 2350ms; }

body[data-aos-duration='2400'] [data-aos], [data-aos][data-aos][data-aos-duration='2400'] {
  transition-duration: 2400ms; }

body[data-aos-delay='2400'] [data-aos], [data-aos][data-aos][data-aos-delay='2400'] {
  transition-delay: 0; }
  body[data-aos-delay='2400'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='2400'].aos-animate {
    transition-delay: 2400ms; }

body[data-aos-duration='2450'] [data-aos], [data-aos][data-aos][data-aos-duration='2450'] {
  transition-duration: 2450ms; }

body[data-aos-delay='2450'] [data-aos], [data-aos][data-aos][data-aos-delay='2450'] {
  transition-delay: 0; }
  body[data-aos-delay='2450'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='2450'].aos-animate {
    transition-delay: 2450ms; }

body[data-aos-duration='2500'] [data-aos], [data-aos][data-aos][data-aos-duration='2500'] {
  transition-duration: 2500ms; }

body[data-aos-delay='2500'] [data-aos], [data-aos][data-aos][data-aos-delay='2500'] {
  transition-delay: 0; }
  body[data-aos-delay='2500'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='2500'].aos-animate {
    transition-delay: 2500ms; }

body[data-aos-duration='2550'] [data-aos], [data-aos][data-aos][data-aos-duration='2550'] {
  transition-duration: 2550ms; }

body[data-aos-delay='2550'] [data-aos], [data-aos][data-aos][data-aos-delay='2550'] {
  transition-delay: 0; }
  body[data-aos-delay='2550'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='2550'].aos-animate {
    transition-delay: 2550ms; }

body[data-aos-duration='2600'] [data-aos], [data-aos][data-aos][data-aos-duration='2600'] {
  transition-duration: 2600ms; }

body[data-aos-delay='2600'] [data-aos], [data-aos][data-aos][data-aos-delay='2600'] {
  transition-delay: 0; }
  body[data-aos-delay='2600'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='2600'].aos-animate {
    transition-delay: 2600ms; }

body[data-aos-duration='2650'] [data-aos], [data-aos][data-aos][data-aos-duration='2650'] {
  transition-duration: 2650ms; }

body[data-aos-delay='2650'] [data-aos], [data-aos][data-aos][data-aos-delay='2650'] {
  transition-delay: 0; }
  body[data-aos-delay='2650'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='2650'].aos-animate {
    transition-delay: 2650ms; }

body[data-aos-duration='2700'] [data-aos], [data-aos][data-aos][data-aos-duration='2700'] {
  transition-duration: 2700ms; }

body[data-aos-delay='2700'] [data-aos], [data-aos][data-aos][data-aos-delay='2700'] {
  transition-delay: 0; }
  body[data-aos-delay='2700'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='2700'].aos-animate {
    transition-delay: 2700ms; }

body[data-aos-duration='2750'] [data-aos], [data-aos][data-aos][data-aos-duration='2750'] {
  transition-duration: 2750ms; }

body[data-aos-delay='2750'] [data-aos], [data-aos][data-aos][data-aos-delay='2750'] {
  transition-delay: 0; }
  body[data-aos-delay='2750'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='2750'].aos-animate {
    transition-delay: 2750ms; }

body[data-aos-duration='2800'] [data-aos], [data-aos][data-aos][data-aos-duration='2800'] {
  transition-duration: 2800ms; }

body[data-aos-delay='2800'] [data-aos], [data-aos][data-aos][data-aos-delay='2800'] {
  transition-delay: 0; }
  body[data-aos-delay='2800'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='2800'].aos-animate {
    transition-delay: 2800ms; }

body[data-aos-duration='2850'] [data-aos], [data-aos][data-aos][data-aos-duration='2850'] {
  transition-duration: 2850ms; }

body[data-aos-delay='2850'] [data-aos], [data-aos][data-aos][data-aos-delay='2850'] {
  transition-delay: 0; }
  body[data-aos-delay='2850'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='2850'].aos-animate {
    transition-delay: 2850ms; }

body[data-aos-duration='2900'] [data-aos], [data-aos][data-aos][data-aos-duration='2900'] {
  transition-duration: 2900ms; }

body[data-aos-delay='2900'] [data-aos], [data-aos][data-aos][data-aos-delay='2900'] {
  transition-delay: 0; }
  body[data-aos-delay='2900'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='2900'].aos-animate {
    transition-delay: 2900ms; }

body[data-aos-duration='2950'] [data-aos], [data-aos][data-aos][data-aos-duration='2950'] {
  transition-duration: 2950ms; }

body[data-aos-delay='2950'] [data-aos], [data-aos][data-aos][data-aos-delay='2950'] {
  transition-delay: 0; }
  body[data-aos-delay='2950'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='2950'].aos-animate {
    transition-delay: 2950ms; }

body[data-aos-duration='3000'] [data-aos], [data-aos][data-aos][data-aos-duration='3000'] {
  transition-duration: 3000ms; }

body[data-aos-delay='3000'] [data-aos], [data-aos][data-aos][data-aos-delay='3000'] {
  transition-delay: 0; }
  body[data-aos-delay='3000'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='3000'].aos-animate {
    transition-delay: 3000ms; }

body[data-aos-easing="linear"] [data-aos], [data-aos][data-aos][data-aos-easing="linear"] {
  transition-timing-function: cubic-bezier(0.25, 0.25, 0.75, 0.75); }

body[data-aos-easing="ease"] [data-aos], [data-aos][data-aos][data-aos-easing="ease"] {
  transition-timing-function: cubic-bezier(0.25, 0.1, 0.25, 1); }

body[data-aos-easing="ease-in"] [data-aos], [data-aos][data-aos][data-aos-easing="ease-in"] {
  transition-timing-function: cubic-bezier(0.42, 0, 1, 1); }

body[data-aos-easing="ease-out"] [data-aos], [data-aos][data-aos][data-aos-easing="ease-out"] {
  transition-timing-function: cubic-bezier(0, 0, 0.58, 1); }

body[data-aos-easing="ease-in-out"] [data-aos], [data-aos][data-aos][data-aos-easing="ease-in-out"] {
  transition-timing-function: cubic-bezier(0.42, 0, 0.58, 1); }

body[data-aos-easing="ease-in-back"] [data-aos], [data-aos][data-aos][data-aos-easing="ease-in-back"] {
  transition-timing-function: cubic-bezier(0.6, -0.28, 0.735, 0.045); }

body[data-aos-easing="ease-out-back"] [data-aos], [data-aos][data-aos][data-aos-easing="ease-out-back"] {
  transition-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1.275); }

body[data-aos-easing="ease-in-out-back"] [data-aos], [data-aos][data-aos][data-aos-easing="ease-in-out-back"] {
  transition-timing-function: cubic-bezier(0.68, -0.55, 0.265, 1.55); }

body[data-aos-easing="ease-in-sine"] [data-aos], [data-aos][data-aos][data-aos-easing="ease-in-sine"] {
  transition-timing-function: cubic-bezier(0.47, 0, 0.745, 0.715); }

body[data-aos-easing="ease-out-sine"] [data-aos], [data-aos][data-aos][data-aos-easing="ease-out-sine"] {
  transition-timing-function: cubic-bezier(0.39, 0.575, 0.565, 1); }

body[data-aos-easing="ease-in-out-sine"] [data-aos], [data-aos][data-aos][data-aos-easing="ease-in-out-sine"] {
  transition-timing-function: cubic-bezier(0.445, 0.05, 0.55, 0.95); }

body[data-aos-easing="ease-in-quad"] [data-aos], [data-aos][data-aos][data-aos-easing="ease-in-quad"] {
  transition-timing-function: cubic-bezier(0.55, 0.085, 0.68, 0.53); }

body[data-aos-easing="ease-out-quad"] [data-aos], [data-aos][data-aos][data-aos-easing="ease-out-quad"] {
  transition-timing-function: cubic-bezier(0.25, 0.46, 0.45, 0.94); }

body[data-aos-easing="ease-in-out-quad"] [data-aos], [data-aos][data-aos][data-aos-easing="ease-in-out-quad"] {
  transition-timing-function: cubic-bezier(0.455, 0.03, 0.515, 0.955); }

body[data-aos-easing="ease-in-cubic"] [data-aos], [data-aos][data-aos][data-aos-easing="ease-in-cubic"] {
  transition-timing-function: cubic-bezier(0.55, 0.085, 0.68, 0.53); }

body[data-aos-easing="ease-out-cubic"] [data-aos], [data-aos][data-aos][data-aos-easing="ease-out-cubic"] {
  transition-timing-function: cubic-bezier(0.25, 0.46, 0.45, 0.94); }

body[data-aos-easing="ease-in-out-cubic"] [data-aos], [data-aos][data-aos][data-aos-easing="ease-in-out-cubic"] {
  transition-timing-function: cubic-bezier(0.455, 0.03, 0.515, 0.955); }

body[data-aos-easing="ease-in-quart"] [data-aos], [data-aos][data-aos][data-aos-easing="ease-in-quart"] {
  transition-timing-function: cubic-bezier(0.55, 0.085, 0.68, 0.53); }

body[data-aos-easing="ease-out-quart"] [data-aos], [data-aos][data-aos][data-aos-easing="ease-out-quart"] {
  transition-timing-function: cubic-bezier(0.25, 0.46, 0.45, 0.94); }

body[data-aos-easing="ease-in-out-quart"] [data-aos], [data-aos][data-aos][data-aos-easing="ease-in-out-quart"] {
  transition-timing-function: cubic-bezier(0.455, 0.03, 0.515, 0.955); }

/**
 * Fade animations:
 * fade
 * fade-up, fade-down, fade-left, fade-right
 * fade-up-right, fade-up-left, fade-down-right, fade-down-left
 */
[data-aos^='fade'][data-aos^='fade'] {
  transition-property: opacity, transform;
  opacity: 0; }
  [data-aos^='fade'][data-aos^='fade'].aos-animate {
    transform: translate3d(0, 0, 0);
    opacity: 1; }

[data-aos='fade-up'] {
  transform: translate3d(0, 100px, 0); }

[data-aos='fade-down'] {
  transform: translate3d(0, -100px, 0); }

[data-aos='fade-right'] {
  transform: translate3d(-100px, 0, 0); }

[data-aos='fade-left'] {
  transform: translate3d(100px, 0, 0); }

[data-aos='fade-up-right'] {
  transform: translate3d(-100px, 100px, 0); }

[data-aos='fade-up-left'] {
  transform: translate3d(100px, 100px, 0); }

[data-aos='fade-down-right'] {
  transform: translate3d(-100px, -100px, 0); }

[data-aos='fade-down-left'] {
  transform: translate3d(100px, -100px, 0); }

/**
 * Zoom animations:
 * zoom-in, zoom-in-up, zoom-in-down, zoom-in-left, zoom-in-right
 * zoom-out, zoom-out-up, zoom-out-down, zoom-out-left, zoom-out-right
 */
[data-aos^='zoom'][data-aos^='zoom'] {
  transition-property: opacity, transform;
  opacity: 0; }
  [data-aos^='zoom'][data-aos^='zoom'].aos-animate {
    transform: translate3d(0, 0, 0) scale(1);
    opacity: 1; }

[data-aos='zoom-in'] {
  transform: scale(0.6); }

[data-aos='zoom-in-up'] {
  transform: translate3d(0, 100px, 0) scale(0.6); }

[data-aos='zoom-in-down'] {
  transform: translate3d(0, -100px, 0) scale(0.6); }

[data-aos='zoom-in-right'] {
  transform: translate3d(-100px, 0, 0) scale(0.6); }

[data-aos='zoom-in-left'] {
  transform: translate3d(100px, 0, 0) scale(0.6); }

[data-aos='zoom-out'] {
  transform: scale(1.2); }

[data-aos='zoom-out-up'] {
  transform: translate3d(0, 100px, 0) scale(1.2); }

[data-aos='zoom-out-down'] {
  transform: translate3d(0, -100px, 0) scale(1.2); }

[data-aos='zoom-out-right'] {
  transform: translate3d(-100px, 0, 0) scale(1.2); }

[data-aos='zoom-out-left'] {
  transform: translate3d(100px, 0, 0) scale(1.2); }

/**
 * Slide animations
 */
[data-aos^='slide'][data-aos^='slide'] {
  transition-property: transform; }
  [data-aos^='slide'][data-aos^='slide'].aos-animate {
    transform: translate3d(0, 0, 0); }

[data-aos='slide-up'] {
  transform: translate3d(0, 100%, 0); }

[data-aos='slide-down'] {
  transform: translate3d(0, -100%, 0); }

[data-aos='slide-right'] {
  transform: translate3d(-100%, 0, 0); }

[data-aos='slide-left'] {
  transform: translate3d(100%, 0, 0); }

/**
 * Flip animations:
 * flip-left, flip-right, flip-up, flip-down
 */
[data-aos^='flip'][data-aos^='flip'] {
  backface-visibility: hidden;
  transition-property: transform; }

[data-aos='flip-left'] {
  transform: perspective(2500px) rotateY(-100deg); }
  [data-aos='flip-left'].aos-animate {
    transform: perspective(2500px) rotateY(0); }

[data-aos='flip-right'] {
  transform: perspective(2500px) rotateY(100deg); }
  [data-aos='flip-right'].aos-animate {
    transform: perspective(2500px) rotateY(0); }

[data-aos='flip-up'] {
  transform: perspective(2500px) rotateX(-100deg); }
  [data-aos='flip-up'].aos-animate {
    transform: perspective(2500px) rotateX(0); }

[data-aos='flip-down'] {
  transform: perspective(2500px) rotateX(100deg); }
  [data-aos='flip-down'].aos-animate {
    transform: perspective(2500px) rotateX(0); }

/* Slider */
/* Icons */
@font-face {
  font-weight: normal;
  font-style: normal; }

/* Arrows */
.slick-prev,
.slick-next {
  position: absolute;
  top: 50%;
  display: block;
  width: 20px;
  height: 20px;
  padding: 0;
  font-size: 0px;
  line-height: 0px;
  cursor: pointer;
  -webkit-transform: translate(0, -50%);
  -ms-transform: translate(0, -50%);
  transform: translate(0, -50%);
  color: transparent;
  border: none;
  outline: none;
  background: transparent; }
  .slick-prev:hover, .slick-prev:focus,
  .slick-next:hover,
  .slick-next:focus {
    color: transparent;
    outline: none;
    background: transparent; }
    .slick-prev:hover:before, .slick-prev:focus:before,
    .slick-next:hover:before,
    .slick-next:focus:before {
      opacity: 1; }
  .slick-prev.slick-disabled:before,
  .slick-next.slick-disabled:before {
    opacity: 0.25; }
  .slick-prev:before,
  .slick-next:before {
    font-family: "slick";
    font-size: 20px;
    line-height: 1;
    opacity: 0.75;
    color: white;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale; }

.slick-prev {
  left: -25px; }
  [dir="rtl"] .slick-prev {
    right: -25px;
    left: auto; }
  .slick-prev:before {
    content: "←"; }
    [dir="rtl"] .slick-prev:before {
      content: "→"; }

.slick-next {
  right: -25px; }
  [dir="rtl"] .slick-next {
    right: auto;
    left: -25px; }
  .slick-next:before {
    content: "→"; }
    [dir="rtl"] .slick-next:before {
      content: "←"; }

/* Dots */
.slick-dotted.slick-slider {
  margin-bottom: 30px; }

.slick-dots {
  position: absolute;
  bottom: -25px;
  display: block;
  width: 100%;
  margin: 0;
  padding: 0;
  list-style: none;
  text-align: center; }
  .slick-dots li {
    position: relative;
    display: inline-block;
    width: 20px;
    height: 20px;
    margin: 0 5px;
    padding: 0;
    cursor: pointer; }
    .slick-dots li button {
      display: block;
      width: 20px;
      height: 20px;
      padding: 5px;
      font-size: 0px;
      line-height: 0px;
      cursor: pointer;
      color: transparent;
      border: 0;
      outline: none;
      background: transparent; }
      .slick-dots li button:hover, .slick-dots li button:focus {
        outline: none; }
        .slick-dots li button:hover:before, .slick-dots li button:focus:before {
          opacity: 1; }
      .slick-dots li button:before {
        content: "•";
        position: absolute;
        top: 0;
        left: 0;
        width: 20px;
        height: 20px;
        font-family: "slick";
        font-size: 6px;
        line-height: 20px;
        text-align: center;
        opacity: 0.25;
        color: black;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale; }
    .slick-dots li.slick-active button:before {
      opacity: 0.75;
      color: black; }

/* Slider */
.slick-slider {
  position: relative;
  display: block;
  box-sizing: border-box;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -ms-touch-action: pan-y;
  touch-action: pan-y;
  -webkit-tap-highlight-color: transparent; }

.slick-list {
  position: relative;
  display: block;
  overflow: hidden;
  margin: 0;
  padding: 0; }
  .slick-list:focus {
    outline: none; }
  .slick-list.dragging {
    cursor: pointer;
    cursor: hand; }

.slick-slider .slick-track,
.slick-slider .slick-list {
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
  -ms-transform: translate3d(0, 0, 0);
  -o-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0); }

.slick-track {
  position: relative;
  top: 0;
  left: 0;
  display: block;
  margin-right: auto;
  margin-left: auto; }
  .slick-track:before, .slick-track:after {
    content: "";
    display: table; }
  .slick-track:after {
    clear: both; }
  .slick-loading .slick-track {
    visibility: hidden; }

.slick-slide {
  display: none;
  float: left;
  height: 100%;
  min-height: 1px; }
  [dir="rtl"] .slick-slide {
    float: right; }
  .slick-slide img {
    display: block; }
  .slick-slide.slick-loading img {
    display: none; }
  .slick-slide.dragging img {
    pointer-events: none; }
  .slick-initialized .slick-slide {
    display: block; }
  .slick-loading .slick-slide {
    visibility: hidden; }
  .slick-vertical .slick-slide {
    display: block;
    height: auto;
    border: 1px solid transparent; }

.slick-arrow.slick-hidden {
  display: none; }

html,
body {
  -webkit-text-size-adjust: 100%; }

html {
  font-size: 62.5%; }

body {
  font-family: "Yu Gothic", YuGothic, "ヒラギノ角ゴ Pro", "Hiragino Kaku Gothic Pro", "メイリオ", "Meiryo", "ＭＳ Ｐゴシック", "MS PGothic", sans-serif;
  font-size: 1.4rem;
  line-height: 1.8;
  word-wrap: break-word;
  color: #333;
  background-color: #fff;
  backface-visibility: hidden;
  -webkit-font-smoothing: antialiased; }

a {
  text-decoration: none;
  color: #d8d8d8;
  outline: none; }

img {
  max-width: 100%;
  height: auto;
  vertical-align: bottom; }

.clearfix:after {
  content: '';
  display: block;
  clear: both; }

.container {
  max-width: 1200px;
  margin-right: auto;
  margin-left: auto;
  position: relative; }

.container-in {
  position: relative;
  margin-right: auto;
  margin-left: auto; }
  @media screen and (min-width: 751px) {
    .container-in {
      max-width: calc( 100% * ( 1080 / 1200 )); } }

@media screen and (max-width: 750px) {
  .pc {
    display: none; } }

@media screen and (min-width: 751px) {
  .sp {
    display: none; } }

.full {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%; }

.full-x {
  position: relative;
  top: 0;
  left: 0;
  width: 100%;
  height: auto; }

.hide-text {
  overflow: hidden;
  text-indent: -1000px;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  pointer-events: none; }

@font-face {
  font-family: "Harenosora";
  src: url("../fonts/hare-subset.woff2") format("woff2"), url("../fonts/hare-subset.woff") format("woff"); }

header h1 {
  font-weight: bold; }

footer {
  position: relative;
  z-index: 1;
  background-color: #6fa1ab; }
  @media screen and (max-width: 750px) {
    footer {
      height: 84vw; } }
  @media screen and (min-width: 751px) and (max-width: 1200px) {
    footer {
      height: 21.25vw; } }
  @media screen and (min-width: 1201px) {
    footer {
      height: 255px; } }

.footer-logo-TONoSORA {
  position: absolute;
  top: 0;
  left: 0;
  display: block;
  background-image: url("../images/logo-TONoSORA.png");
  background-repeat: no-repeat;
  background-size: 100% 100%; }
  @media screen and (max-width: 750px) {
    .footer-logo-TONoSORA {
      right: 0;
      width: 24.13333%;
      margin: 13.33333% auto 0;
      padding-bottom: 29.2%; } }
  @media screen and (min-width: 751px) {
    .footer-logo-TONoSORA {
      width: 11.66667%;
      margin: 4.62963% 0 0 0;
      padding-bottom: 14.35185%; } }

.sns-instragram {
  position: absolute;
  top: 0;
  display: block;
  background-image: url("../images/sns-instragram.png");
  background-repeat: no-repeat;
  background-size: 100% 100%; }
  @media screen and (max-width: 750px) {
    .sns-instragram {
      right: 0;
      right: 0;
      left: 0;
      width: 6.8%;
      margin: 54.4% auto 0;
      padding-bottom: 6.8%; } }
  @media screen and (min-width: 751px) {
    .sns-instragram {
      right: 0;
      width: 2.68519%;
      margin: 9.62963% 0 0 0;
      padding-bottom: 2.68519%; } }

.copyright-w {
  position: absolute;
  top: 0;
  font-family: 'Lato', sans-serif;
  line-height: 1;
  color: #fff; }
  @media screen and (max-width: 750px) {
    .copyright-w {
      left: 0;
      width: 100%;
      margin: 67.6% 0 0 0;
      text-align: center; } }
  @media screen and (min-width: 751px) {
    .copyright-w {
      right: 0;
      width: 50%;
      margin: 17.87037% 0 0 0;
      text-align: right; } }

@keyframes intro {
  0% {
    opacity: 0; }
  10% {
    transform: scale(1);
    opacity: 1; }
  35% {
    transform: scale(1);
    opacity: 1; }
  100% {
    transform: scale(1.5);
    opacity: 0; } }

body.start-intro .fix-w-bg {
  transition: 0.1s 2s;
  opacity: 0; }

body.start-intro .fix-intro {
  animation-name: intro;
  animation-duration: 8s;
  animation-fill-mode: both; }

body.start-intro .door-frame:before {
  transition: transform 3.8s 0.6s;
  transform: translate(-100%, 0); }

body.start-intro .door-frame:after {
  transition: transform 3.8s 0.6s;
  transform: translate(100%, 0); }

.wrapper {
  position: relative;
  top: 0;
  left: 0;
  overflow-x: hidden;
  width: 100%;
  height: auto; }

section {
  position: relative;
  top: 0;
  left: 0;
  width: 100%;
  height: auto; }

.intro-frame {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%); }

.fix-w-bg {
  position: fixed;
  z-index: 5;
  top: 0;
  left: 0;
  overflow: hidden;
  width: 100%;
  height: 100%;
  pointer-events: none;
  background-color: #fff; }

.fix-intro {
  position: fixed;
  z-index: 5;
  top: 0;
  left: 0;
  overflow: hidden;
  width: 100%;
  height: 100%;
  pointer-events: none;
  opacity: 0;
  background-color: #fff; }
  @media screen and (max-width: 750px) {
    .fix-intro.fix-intro-pc {
      display: none; } }
  @media screen and (min-width: 751px) {
    .fix-intro.fix-intro-sp {
      display: none; } }
  .end-intro .fix-intro {
    animation: none !important;
    opacity: 0 !important; }
  .fix-intro.fix-intro-sp .door-frame {
    position: absolute;
    overflow: hidden;
    width: calc( 100% * ( 487 / 750 ));
    margin: calc( 100% * ( 426 / 750 )) 0 0 calc( 100% * ( 132 / 750 ));
    padding-bottom: calc( 100% * ( 508 / 750 )); }
    .fix-intro.fix-intro-sp .door-frame:before {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      display: block;
      width: calc( 100% * ( 251 / 487 ));
      height: 100%;
      background-image: url("../images/tobira/SP/SP_tobira_left.png");
      background-size: 100% 100%; }
    .fix-intro.fix-intro-sp .door-frame:after {
      content: "";
      position: absolute;
      top: 0;
      right: 0;
      display: block;
      width: calc( 100% * ( 257 / 487 ));
      height: 100%;
      background-image: url("../images/tobira/SP/SP_tobira_right.png");
      background-size: 100% 100%; }
  .fix-intro.fix-intro-sp .intro-frame {
    background-image: url("../images/tobira/SP/SP_waku.jpg");
    background-size: 100% 100%; }
    @media (min-aspect-ratio: 750 / 1334) {
      .fix-intro.fix-intro-sp .intro-frame {
        width: 100vw;
        height: calc( 100vw * ( 1334 / 750 )); } }
    @media (max-aspect-ratio: 750 / 1334) {
      .fix-intro.fix-intro-sp .intro-frame {
        width: calc( 100vh * ( 750 / 1334 ));
        height: 100vh; } }
  .fix-intro.fix-intro-pc .door-frame {
    position: absolute;
    overflow: hidden;
    width: calc( 100% * ( 487 / 1600 ));
    margin: calc( 100% * ( 165 / 1600 )) 0 0 calc( 100% * ( 558 / 1600 ));
    padding-bottom: calc( 100% * ( 508 / 1600 )); }
    .fix-intro.fix-intro-pc .door-frame:before {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      display: block;
      width: calc( 100% * ( 251 / 487 ));
      height: 100%;
      background-image: url("../images/tobira/PC/PC_tobira_left.png");
      background-size: 100% 100%; }
    .fix-intro.fix-intro-pc .door-frame:after {
      content: "";
      position: absolute;
      top: 0;
      right: 0;
      display: block;
      width: calc( 100% * ( 257 / 487 ));
      height: 100%;
      background-image: url("../images/tobira/PC/PC_tobira_right.png");
      background-size: 100% 100%; }
  .fix-intro.fix-intro-pc .intro-frame {
    background-image: url("../images/tobira/PC/PC_waku.jpg");
    background-size: 100% 100%; }
    @media (min-aspect-ratio: 1600 / 800) {
      .fix-intro.fix-intro-pc .intro-frame {
        width: 100vw;
        height: calc( 100vw * ( 800 / 1600 )); } }
    @media (max-aspect-ratio: 1600 / 800) {
      .fix-intro.fix-intro-pc .intro-frame {
        width: calc( 100vh * ( 1600 / 800 ));
        height: 100vh; } }

.sec-kv {
  background-color: #fff; }
  @media screen and (max-width: 750px) {
    .sec-kv {
      height: 200vw; } }
  @media screen and (min-width: 751px) {
    .sec-kv {
      height: calc( 100vw * ( 700 / 1600 )); } }

@media screen and (max-width: 750px) {
  .kv-carousel-item {
    height: 200vw; } }

@media screen and (min-width: 751px) {
  .kv-carousel-item {
    height: calc( 100vw * ( 700 / 1600 )); } }

@media screen and (max-width: 750px) {
  .kv-carousel-pc {
    position: absolute;
    pointer-events: none;
    opacity: 0; } }

.kv-carousel-pc .kv-carousel-item {
  background-repeat: no-repeat;
  background-position: 50% 0;
  background-size: auto 100%; }
  @media screen and (min-width: 751px) {
    .kv-carousel-pc .kv-carousel-item {
      background-size: cover; } }
  .kv-carousel-pc .kv-carousel-item[data-pic="01"] {
    background-image: url("../images/kv-01-pc.jpg"); }
  .kv-carousel-pc .kv-carousel-item[data-pic="02"] {
    background-image: url("../images/kv-02-pc.jpg"); }
  .kv-carousel-pc .kv-carousel-item[data-pic="03"] {
    background-image: url("../images/kv-03-pc.jpg"); }
  .kv-carousel-pc .kv-carousel-item[data-pic="04"] {
    background-image: url("../images/kv-04-pc.jpg"); }
  .kv-carousel-pc .kv-carousel-item[data-pic="05"] {
    background-image: url("../images/kv-05-pc.jpg"); }
  .kv-carousel-pc .kv-carousel-item[data-pic="06"] {
    background-image: url("../images/kv-06-pc.jpg"); }
  .kv-carousel-pc .kv-carousel-item[data-pic="07"] {
    background-image: url("../images/kv-07-pc.jpg"); }

@media screen and (min-width: 751px) {
  .kv-carousel-sp {
    position: absolute;
    pointer-events: none;
    opacity: 0; } }

.kv-carousel-sp .kv-carousel-item {
  background-repeat: no-repeat;
  background-position: 0 0;
  background-size: 100% 100%; }
  .kv-carousel-sp .kv-carousel-item[data-pic="00"] {
    background-image: url("../images/kv-00-sp.jpg"); }
  .kv-carousel-sp .kv-carousel-item[data-pic="01"] {
    background-image: url("../images/kv-01-sp.jpg"); }
  .kv-carousel-sp .kv-carousel-item[data-pic="02"] {
    background-image: url("../images/kv-02-sp.jpg"); }
  .kv-carousel-sp .kv-carousel-item[data-pic="03"] {
    background-image: url("../images/kv-03-sp.jpg"); }
  .kv-carousel-sp .kv-carousel-item[data-pic="04"] {
    background-image: url("../images/kv-04-sp.jpg"); }
  .kv-carousel-sp .kv-carousel-item[data-pic="05"] {
    background-image: url("../images/kv-05-sp.jpg"); }
  .kv-carousel-sp .kv-carousel-item[data-pic="06"] {
    background-image: url("../images/kv-06-sp.jpg"); }
  .kv-carousel-sp .kv-carousel-item[data-pic="07"] {
    background-image: url("../images/kv-07-sp.jpg"); }

[data-aos='fade-up'] {
  transform: translate3d(0, 50px, 0); }

body[data-aos-easing="ease"] [data-aos], [data-aos][data-aos][data-aos-easing="ease"] {
  transition-timing-function: cubic-bezier(0.81, 0.37, 0.2, 0.6); }

p.ani-p > span {
  transition: opacity 0.3s 0s, transform  0.3s 0s;
  transform: translate3d(0, 10px, 0);
  opacity: 0; }

p.ani-p.aos-animate > span {
  opacity: 1; }
  p.ani-p.aos-animate > span:nth-of-type(0) {
    transition: opacity 0.9s 0.5s, transform 0.9s 0.5s;
    transform: translate3d(0, 0, 0); }
  p.ani-p.aos-animate > span:nth-of-type(1) {
    transition: opacity 0.9s 0.52s, transform 0.9s 0.52s;
    transform: translate3d(0, 0, 0); }
  p.ani-p.aos-animate > span:nth-of-type(2) {
    transition: opacity 0.9s 0.54s, transform 0.9s 0.54s;
    transform: translate3d(0, 0, 0); }
  p.ani-p.aos-animate > span:nth-of-type(3) {
    transition: opacity 0.9s 0.56s, transform 0.9s 0.56s;
    transform: translate3d(0, 0, 0); }
  p.ani-p.aos-animate > span:nth-of-type(4) {
    transition: opacity 0.9s 0.58s, transform 0.9s 0.58s;
    transform: translate3d(0, 0, 0); }
  p.ani-p.aos-animate > span:nth-of-type(5) {
    transition: opacity 0.9s 0.6s, transform 0.9s 0.6s;
    transform: translate3d(0, 0, 0); }
  p.ani-p.aos-animate > span:nth-of-type(6) {
    transition: opacity 0.9s 0.62s, transform 0.9s 0.62s;
    transform: translate3d(0, 0, 0); }
  p.ani-p.aos-animate > span:nth-of-type(7) {
    transition: opacity 0.9s 0.64s, transform 0.9s 0.64s;
    transform: translate3d(0, 0, 0); }
  p.ani-p.aos-animate > span:nth-of-type(8) {
    transition: opacity 0.9s 0.66s, transform 0.9s 0.66s;
    transform: translate3d(0, 0, 0); }
  p.ani-p.aos-animate > span:nth-of-type(9) {
    transition: opacity 0.9s 0.68s, transform 0.9s 0.68s;
    transform: translate3d(0, 0, 0); }
  p.ani-p.aos-animate > span:nth-of-type(10) {
    transition: opacity 0.9s 0.7s, transform 0.9s 0.7s;
    transform: translate3d(0, 0, 0); }
  p.ani-p.aos-animate > span:nth-of-type(11) {
    transition: opacity 0.9s 0.72s, transform 0.9s 0.72s;
    transform: translate3d(0, 0, 0); }
  p.ani-p.aos-animate > span:nth-of-type(12) {
    transition: opacity 0.9s 0.74s, transform 0.9s 0.74s;
    transform: translate3d(0, 0, 0); }
  p.ani-p.aos-animate > span:nth-of-type(13) {
    transition: opacity 0.9s 0.76s, transform 0.9s 0.76s;
    transform: translate3d(0, 0, 0); }
  p.ani-p.aos-animate > span:nth-of-type(14) {
    transition: opacity 0.9s 0.78s, transform 0.9s 0.78s;
    transform: translate3d(0, 0, 0); }
  p.ani-p.aos-animate > span:nth-of-type(15) {
    transition: opacity 0.9s 0.8s, transform 0.9s 0.8s;
    transform: translate3d(0, 0, 0); }
  p.ani-p.aos-animate > span:nth-of-type(16) {
    transition: opacity 0.9s 0.82s, transform 0.9s 0.82s;
    transform: translate3d(0, 0, 0); }
  p.ani-p.aos-animate > span:nth-of-type(17) {
    transition: opacity 0.9s 0.84s, transform 0.9s 0.84s;
    transform: translate3d(0, 0, 0); }
  p.ani-p.aos-animate > span:nth-of-type(18) {
    transition: opacity 0.9s 0.86s, transform 0.9s 0.86s;
    transform: translate3d(0, 0, 0); }
  p.ani-p.aos-animate > span:nth-of-type(19) {
    transition: opacity 0.9s 0.88s, transform 0.9s 0.88s;
    transform: translate3d(0, 0, 0); }
  p.ani-p.aos-animate > span:nth-of-type(20) {
    transition: opacity 0.9s 0.9s, transform 0.9s 0.9s;
    transform: translate3d(0, 0, 0); }
  p.ani-p.aos-animate > span:nth-of-type(21) {
    transition: opacity 0.9s 0.92s, transform 0.9s 0.92s;
    transform: translate3d(0, 0, 0); }
  p.ani-p.aos-animate > span:nth-of-type(22) {
    transition: opacity 0.9s 0.94s, transform 0.9s 0.94s;
    transform: translate3d(0, 0, 0); }
  p.ani-p.aos-animate > span:nth-of-type(23) {
    transition: opacity 0.9s 0.96s, transform 0.9s 0.96s;
    transform: translate3d(0, 0, 0); }

h3.title {
  font-family: "Harenosora"; }
  @media screen and (max-width: 750px) {
    h3.title {
      font-size: 5.86667vw;
      line-height: 1.82;
      letter-spacing: 1.46667vw; }
      .ie h3.title {
        letter-spacing: 0.73333vw; }
      .edge h3.title {
        letter-spacing: 0 !important; } }
  @media screen and (min-width: 751px) {
    h3.title .dot {
      position: relative;
      top: -0.6em;
      left: 0.6em; }
      .ie h3.title .dot {
        left: -0.6em; } }
  @media screen and (min-width: 751px) and (max-width: 1200px) {
    h3.title {
      font-size: 2.91667vw;
      line-height: 5.08333vw;
      letter-spacing: 0.66667vw; }
      .ie h3.title {
        letter-spacing: 0.33333vw; } }
  @media screen and (min-width: 1201px) {
    h3.title {
      font-size: 35px;
      line-height: 61px;
      letter-spacing: 8px; }
      .ie h3.title {
        letter-spacing: 4px; } }
  h3.title span {
    transition: opacity 0.3s 0s;
    opacity: 0; }
  h3.title.aos-animate span {
    opacity: 1; }
    h3.title.aos-animate span:nth-of-type(0) {
      transition: opacity 0.8s 0s; }
    h3.title.aos-animate span:nth-of-type(1) {
      transition: opacity 0.8s 0.05s; }
    h3.title.aos-animate span:nth-of-type(2) {
      transition: opacity 0.8s 0.1s; }
    h3.title.aos-animate span:nth-of-type(3) {
      transition: opacity 0.8s 0.15s; }
    h3.title.aos-animate span:nth-of-type(4) {
      transition: opacity 0.8s 0.2s; }
    h3.title.aos-animate span:nth-of-type(5) {
      transition: opacity 0.8s 0.25s; }
    h3.title.aos-animate span:nth-of-type(6) {
      transition: opacity 0.8s 0.3s; }
    h3.title.aos-animate span:nth-of-type(7) {
      transition: opacity 0.8s 0.35s; }
    h3.title.aos-animate span:nth-of-type(8) {
      transition: opacity 0.8s 0.4s; }
    h3.title.aos-animate span:nth-of-type(9) {
      transition: opacity 0.8s 0.45s; }
    h3.title.aos-animate span:nth-of-type(10) {
      transition: opacity 0.8s 0.5s; }
    h3.title.aos-animate span:nth-of-type(11) {
      transition: opacity 0.8s 0.55s; }
    h3.title.aos-animate span:nth-of-type(12) {
      transition: opacity 0.8s 0.6s; }
    h3.title.aos-animate span:nth-of-type(13) {
      transition: opacity 0.8s 0.65s; }
    h3.title.aos-animate span:nth-of-type(14) {
      transition: opacity 0.8s 0.7s; }
    h3.title.aos-animate span:nth-of-type(15) {
      transition: opacity 0.8s 0.75s; }
    h3.title.aos-animate span:nth-of-type(16) {
      transition: opacity 0.8s 0.8s; }
    h3.title.aos-animate span:nth-of-type(17) {
      transition: opacity 0.8s 0.85s; }
    h3.title.aos-animate span:nth-of-type(18) {
      transition: opacity 0.8s 0.9s; }
    h3.title.aos-animate span:nth-of-type(19) {
      transition: opacity 0.8s 0.95s; }
    h3.title.aos-animate span:nth-of-type(20) {
      transition: opacity 0.8s 1s; }
    h3.title.aos-animate span:nth-of-type(21) {
      transition: opacity 0.8s 1.05s; }
    h3.title.aos-animate span:nth-of-type(22) {
      transition: opacity 0.8s 1.1s; }
    h3.title.aos-animate span:nth-of-type(23) {
      transition: opacity 0.8s 1.15s; }
  .sec-concept-statement h3.title {
    color: #404040;
    -ms-writing-mode: tb-rl;
    writing-mode: vertical-rl;
    text-orientation: mixed; }
    @media screen and (max-width: 750px) {
      .sec-concept-statement h3.title {
        letter-spacing: 1.2vw; }
        .edge .sec-concept-statement h3.title {
          letter-spacing: 0 !important; } }
  .sec-kakurega h3.title {
    color: #fff; }
    @media screen and (max-width: 750px) {
      .sec-kakurega h3.title {
        line-height: 1;
        letter-spacing: 1.06667vw; }
        .edge .sec-kakurega h3.title {
          letter-spacing: 0 !important; } }
    @media screen and (min-width: 751px) {
      .sec-kakurega h3.title {
        -ms-writing-mode: tb-rl;
        writing-mode: vertical-rl;
        text-orientation: mixed; } }
    @media screen and (min-width: 751px) and (max-width: 1200px) {
      .sec-kakurega h3.title {
        line-height: 5.33333vw; } }
    @media screen and (min-width: 1201px) {
      .sec-kakurega h3.title {
        line-height: 64px; } }
  .sec-kodawari h3.title {
    color: #404040; }
    @media screen and (max-width: 750px) {
      .sec-kodawari h3.title {
        line-height: 1;
        letter-spacing: 0.8vw; }
        .edge .sec-kodawari h3.title {
          letter-spacing: 0 !important; } }
    @media screen and (min-width: 751px) {
      .sec-kodawari h3.title {
        -ms-writing-mode: tb-rl;
        writing-mode: vertical-rl;
        text-orientation: mixed; } }
    @media screen and (min-width: 751px) and (max-width: 1200px) {
      .sec-kodawari h3.title {
        line-height: 5.33333vw; } }
    @media screen and (min-width: 1201px) {
      .sec-kodawari h3.title {
        line-height: 64px; } }
  .sec-akogareruaji h3.title {
    color: #fff; }
    @media screen and (max-width: 750px) {
      .sec-akogareruaji h3.title {
        line-height: 1;
        letter-spacing: 1.06667vw; }
        .edge .sec-akogareruaji h3.title {
          letter-spacing: 0 !important; } }
    @media screen and (min-width: 751px) {
      .sec-akogareruaji h3.title {
        -ms-writing-mode: tb-rl;
        writing-mode: vertical-rl;
        text-orientation: mixed; } }
    @media screen and (min-width: 751px) and (max-width: 1200px) {
      .sec-akogareruaji h3.title {
        line-height: 5.41667vw; } }
    @media screen and (min-width: 1201px) {
      .sec-akogareruaji h3.title {
        line-height: 65px; } }
  .sec-yorokobi h3.title {
    color: #404040; }
    @media screen and (max-width: 750px) {
      .sec-yorokobi h3.title {
        line-height: 1.8;
        letter-spacing: 1.06667vw; }
        .edge .sec-yorokobi h3.title {
          letter-spacing: 0 !important; } }
    @media screen and (min-width: 751px) {
      .sec-yorokobi h3.title {
        -ms-writing-mode: tb-rl;
        writing-mode: vertical-rl;
        text-orientation: mixed; } }
    @media screen and (min-width: 751px) and (max-width: 1200px) {
      .sec-yorokobi h3.title {
        line-height: 5.41667vw;
        letter-spacing: 0.83333vw; } }
    @media screen and (min-width: 1201px) {
      .sec-yorokobi h3.title {
        line-height: 65px;
        letter-spacing: 10px; } }
  .sec-shinsyokan h3.title {
    color: #404040; }
    @media screen and (max-width: 750px) {
      .sec-shinsyokan h3.title {
        line-height: 1;
        letter-spacing: 0.66667vw; }
        .edge .sec-shinsyokan h3.title {
          letter-spacing: 0 !important; } }
    @media screen and (min-width: 751px) {
      .sec-shinsyokan h3.title {
        -ms-writing-mode: tb-rl;
        writing-mode: vertical-rl;
        text-orientation: mixed; } }
    @media screen and (min-width: 751px) and (max-width: 1200px) {
      .sec-shinsyokan h3.title {
        line-height: 5.41667vw;
        letter-spacing: 0.83333vw; } }
    @media screen and (min-width: 1201px) {
      .sec-shinsyokan h3.title {
        line-height: 65px;
        letter-spacing: 10px; } }
  .sec-gift h3.title {
    color: #fff;
    -ms-writing-mode: tb-rl;
    writing-mode: vertical-rl;
    text-orientation: mixed; }
    @media screen and (max-width: 750px) {
      .sec-gift h3.title {
        line-height: 1.8;
        letter-spacing: 1.2vw; }
        .edge .sec-gift h3.title {
          letter-spacing: -2.01vw !important; } }
    @media screen and (min-width: 751px) and (max-width: 1200px) {
      .sec-gift h3.title {
        font-size: 4vw;
        line-height: 7vw; } }
    @media screen and (min-width: 1201px) {
      .sec-gift h3.title {
        font-size: 48px;
        line-height: 84px; } }
  .sec-gohoubi h3.title {
    color: #fff;
    -ms-writing-mode: tb-rl;
    writing-mode: vertical-rl;
    text-orientation: mixed; }
    @media screen and (max-width: 750px) {
      .sec-gohoubi h3.title {
        line-height: 1.8;
        letter-spacing: 2.13333vw; }
        .edge .sec-gohoubi h3.title {
          letter-spacing: -2.01vw !important; } }
    @media screen and (min-width: 751px) and (max-width: 1200px) {
      .sec-gohoubi h3.title {
        font-size: 4vw;
        line-height: 7vw; } }
    @media screen and (min-width: 1201px) {
      .sec-gohoubi h3.title {
        font-size: 48px;
        line-height: 84px; } }
  h3.title .title-in {
    position: absolute;
    top: 0;
    display: inline-block;
    width: 100%;
    height: 120%;
    white-space: pre; }
    @media screen and (max-width: 750px) {
      .sec-concept-statement h3.title .title-in {
        left: 20%; } }
    @media screen and (min-width: 751px) {
      .sec-concept-statement h3.title .title-in {
        left: 20%; } }
    @media screen and (min-width: 751px) {
      .sec-kakurega h3.title .title-in {
        left: 15%; } }
    @media screen and (min-width: 751px) {
      .sec-kodawari h3.title .title-in {
        left: 15%; } }
    @media screen and (min-width: 751px) {
      .sec-akogareruaji h3.title .title-in {
        left: 15%; } }
    @media screen and (max-width: 750px) {
      .sec-yorokobi h3.title .title-in {
        top: -10%; } }
    @media screen and (min-width: 751px) {
      .sec-yorokobi h3.title .title-in {
        left: 15%; } }
    @media screen and (min-width: 751px) {
      .sec-shinsyokan h3.title .title-in {
        left: 15%; } }
    @media screen and (max-width: 750px) {
      .sec-gift h3.title .title-in {
        left: 17%; }
        .sec-gift h3.title .title-in span:nth-of-type(1) {
          color: #404040; }
        .sec-gift h3.title .title-in span:nth-of-type(2) {
          color: #404040; }
        .sec-gift h3.title .title-in span:nth-of-type(3) {
          color: #404040; }
        .sec-gift h3.title .title-in span:nth-of-type(7) {
          color: #404040; } }
    @media screen and (min-width: 751px) {
      .sec-gift h3.title .title-in {
        left: 15%; } }
    @media screen and (max-width: 750px) {
      .sec-gohoubi h3.title .title-in {
        left: 15%; }
        .sec-gohoubi h3.title .title-in span:nth-of-type(1) {
          color: #404040; }
        .sec-gohoubi h3.title .title-in span:nth-of-type(2) {
          color: #404040; }
        .sec-gohoubi h3.title .title-in span:nth-of-type(3) {
          color: #404040; }
        .sec-gohoubi h3.title .title-in span:nth-of-type(4) {
          color: #404040; }
        .sec-gohoubi h3.title .title-in span:nth-of-type(5) {
          color: #404040; }
        .sec-gohoubi h3.title .title-in span:nth-of-type(6) {
          color: #404040; } }
    @media screen and (min-width: 751px) {
      .sec-gohoubi h3.title .title-in {
        left: 15%; } }
  @media screen and (min-width: 751px) and (max-width: 1200px) {
    .sec-concept-statement h3.title .title-indent {
      margin-top: 4vw; } }
  @media screen and (min-width: 1201px) {
    .sec-concept-statement h3.title .title-indent {
      margin-top: 48px; } }
  @media screen and (min-width: 751px) and (max-width: 1200px) {
    .sec-kakurega h3.title .title-indent {
      margin-top: 6.91667vw; } }
  @media screen and (min-width: 1201px) {
    .sec-kakurega h3.title .title-indent {
      margin-top: 83px; } }
  @media screen and (min-width: 751px) and (max-width: 1200px) {
    .sec-kodawari h3.title .title-indent {
      margin-top: 11.08333vw; } }
  @media screen and (min-width: 1201px) {
    .sec-kodawari h3.title .title-indent {
      margin-top: 133px; } }
  @media screen and (min-width: 751px) and (max-width: 1200px) {
    .sec-akogareruaji h3.title .title-indent {
      margin-top: 7vw; } }
  @media screen and (min-width: 1201px) {
    .sec-akogareruaji h3.title .title-indent {
      margin-top: 84px; } }
  @media screen and (min-width: 751px) and (max-width: 1200px) {
    .sec-yorokobi h3.title .title-indent {
      margin-top: 9.16667vw; } }
  @media screen and (min-width: 1201px) {
    .sec-yorokobi h3.title .title-indent {
      margin-top: 110px; } }
  @media screen and (min-width: 751px) and (max-width: 1200px) {
    .sec-shinsyokan h3.title .title-indent {
      margin-top: 3.75vw; } }
  @media screen and (min-width: 1201px) {
    .sec-shinsyokan h3.title .title-indent {
      margin-top: 45px; } }
  @media screen and (max-width: 750px) {
    .sec-gift h3.title .title-indent {
      margin-top: 15.2vw; } }
  @media screen and (min-width: 751px) and (max-width: 1200px) {
    .sec-gift h3.title .title-indent {
      margin-top: 10.25vw; } }
  @media screen and (min-width: 1201px) {
    .sec-gift h3.title .title-indent {
      margin-top: 123px; } }
  @media screen and (max-width: 750px) {
    .sec-gohoubi h3.title .title-indent {
      margin-top: 18.66667vw; } }
  @media screen and (min-width: 751px) and (max-width: 1200px) {
    .sec-gohoubi h3.title .title-indent {
      margin-top: 9.75vw; } }
  @media screen and (min-width: 1201px) {
    .sec-gohoubi h3.title .title-indent {
      margin-top: 117px; } }

.sec-concept-statement {
  background-repeat: no-repeat;
  background-position: 50% 0;
  background-size: auto 100%; }
  @media screen and (max-width: 750px) {
    .sec-concept-statement {
      height: 238.93333vw;
      background-image: url("../images/bg-Concept_statement-sp.jpg"); } }
  @media screen and (min-width: 751px) {
    .sec-concept-statement {
      background-image: url("../images/bg-Concept_statement-pc.jpg"); } }
  @media screen and (min-width: 751px) and (max-width: 1200px) {
    .sec-concept-statement {
      height: 74.58333vw; } }
  @media screen and (min-width: 1201px) {
    .sec-concept-statement {
      height: 895px; } }
  .sec-concept-statement h2.title {
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    display: block;
    background-repeat: no-repeat;
    background-size: 100% 100%; }
    @media screen and (max-width: 750px) {
      .sec-concept-statement h2.title {
        width: 75.6%;
        margin: 25.86667% auto 0;
        padding-bottom: 8%;
        background-image: url("../images/h2-Concept_statement-sp.png"); } }
    @media screen and (min-width: 751px) {
      .sec-concept-statement h2.title {
        width: 43.7037%;
        margin: 13.7963% auto 0;
        padding-bottom: 4.62963%;
        background-image: url("../images/h2-Concept_statement-pc.png"); } }
  .sec-concept-statement h3.title {
    position: absolute;
    top: 0;
    right: 0;
    display: block;
    background-repeat: no-repeat;
    background-size: 100% 100%; }
    @media screen and (max-width: 750px) {
      .sec-concept-statement h3.title {
        width: 15.2%;
        margin: 54.66667% 13.2% 0 0;
        padding-bottom: 53.6%; }
        .sec-concept-statement h3.title .dot {
          position: relative;
          top: -0.6em;
          left: 0.6em; } }
    @media screen and (min-width: 751px) {
      .sec-concept-statement h3.title {
        width: 8.24074%;
        margin: 22.96296% 0 0 0;
        padding-bottom: 33.24074%; } }
  .sec-concept-statement p.ani-p {
    position: absolute;
    top: 0;
    color: #404040; }
    @media screen and (max-width: 750px) {
      .sec-concept-statement p.ani-p {
        left: 0;
        margin: 116.13333% 0 0 5.33333%;
        padding-right: 5.33333%; } }
    @media screen and (min-width: 751px) {
      .sec-concept-statement p.ani-p {
        right: 0;
        margin: 27.68519% 11.66667% 0 0; }
        .sec-concept-statement p.ani-p .quotation-mark-start {
          position: relative;
          display: inline-block;
          transform: rotate(90deg);
          transform-origin: 65% 65%; }
          .ie .sec-concept-statement p.ani-p .quotation-mark-start {
            transform: rotate(0deg); }
        .sec-concept-statement p.ani-p .quotation-mark-end {
          position: relative;
          display: inline-block;
          transform: rotate(90deg);
          transform-origin: 55% -35%; }
          .ie .sec-concept-statement p.ani-p .quotation-mark-end {
            transform: rotate(0deg); } }
    .sec-concept-statement p.ani-p > span {
      position: relative; }
      @media screen and (max-width: 750px) {
        .sec-concept-statement p.ani-p > span {
          display: inline;
          font-size: 3.73333vw;
          line-height: 7.2vw;
          letter-spacing: 0; }
          .ie .sec-concept-statement p.ani-p > span {
            letter-spacing: 0; } }
      @media screen and (min-width: 751px) {
        .sec-concept-statement p.ani-p > span {
          display: block;
          float: right;
          letter-spacing: 2px; }
          .ie .sec-concept-statement p.ani-p > span {
            letter-spacing: 0; } }
      @media screen and (min-width: 751px) and (max-width: 1200px) {
        .sec-concept-statement p.ani-p > span {
          width: 3.58333vw;
          font-size: 1.33333vw;
          line-height: 3.58333vw;
          -ms-writing-mode: tb-rl;
          writing-mode: vertical-rl;
          text-orientation: mixed; } }
      @media screen and (min-width: 1201px) {
        .sec-concept-statement p.ani-p > span {
          width: 42px;
          font-size: 16px;
          line-height: 42px;
          -ms-writing-mode: tb-rl;
          writing-mode: vertical-rl;
          text-orientation: mixed; } }

@media screen and (max-width: 750px) {
  .sp-t-r {
    display: inline-block !important;
    width: 100%;
    text-align: right; } }

@media screen and (min-width: 751px) and (max-width: 1200px) {
  .sp-t-r {
    top: 26.66667vw; } }

@media screen and (min-width: 1201px) {
  .sp-t-r {
    top: 320px; } }

.sec-kakurega {
  background-repeat: no-repeat;
  background-position: 50% 0;
  background-size: auto 100%; }
  @media screen and (max-width: 750px) {
    .sec-kakurega {
      height: 136.66667vw;
      background-image: url("../images/bg-kakurega-sp.jpg"); } }
  @media screen and (min-width: 751px) {
    .sec-kakurega {
      background-image: url("../images/bg-kakurega-pc.jpg"); } }
  @media screen and (min-width: 751px) and (max-width: 1200px) {
    .sec-kakurega {
      height: 74.58333vw; } }
  @media screen and (min-width: 1201px) {
    .sec-kakurega {
      height: 895px; } }
  .sec-kakurega h3.title {
    position: absolute;
    top: 0;
    left: 0;
    display: block;
    background-repeat: no-repeat;
    background-size: 100% 100%; }
    @media screen and (max-width: 750px) {
      .sec-kakurega h3.title {
        width: 42.26667%;
        margin: 29.6% 0 0 5.33333%;
        padding-bottom: 5.73333%; } }
    @media screen and (min-width: 751px) {
      .sec-kakurega h3.title {
        width: 8.98148%;
        margin: 12.68519% 0 0 38.14815%;
        padding-bottom: 18.7963%; } }
  .sec-kakurega p.ani-p {
    position: absolute;
    top: 0;
    color: #fff; }
    @media screen and (max-width: 750px) {
      .sec-kakurega p.ani-p {
        left: 0;
        margin: 47.33333% 0 0 5.33333%;
        padding-right: 5.33333%; } }
    @media screen and (min-width: 751px) {
      .sec-kakurega p.ani-p {
        right: 0;
        margin: 20.09259% 68.05556% 0 0; } }
    .sec-kakurega p.ani-p span {
      position: relative; }
      @media screen and (max-width: 750px) {
        .sec-kakurega p.ani-p span {
          display: inline;
          font-size: 3.73333vw;
          line-height: 7.2vw;
          letter-spacing: 0.53333vw; } }
      @media screen and (min-width: 751px) {
        .sec-kakurega p.ani-p span {
          display: block;
          float: right;
          letter-spacing: 2px; } }
      @media screen and (min-width: 751px) and (max-width: 1200px) {
        .sec-kakurega p.ani-p span {
          width: 3.58333vw;
          font-size: 1.33333vw;
          line-height: 3.58333vw;
          -ms-writing-mode: tb-rl;
          writing-mode: vertical-rl;
          text-orientation: mixed; } }
      @media screen and (min-width: 1201px) {
        .sec-kakurega p.ani-p span {
          width: 42px;
          font-size: 16px;
          line-height: 42px;
          -ms-writing-mode: tb-rl;
          writing-mode: vertical-rl;
          text-orientation: mixed; } }

.sec-kodawari {
  background-repeat: no-repeat;
  background-position: 50% 0;
  background-size: auto 100%; }
  @media screen and (max-width: 750px) {
    .sec-kodawari {
      height: 199.33333vw; } }
  @media screen and (min-width: 751px) and (max-width: 1200px) {
    .sec-kodawari {
      height: 93.58333vw; } }
  @media screen and (min-width: 1201px) {
    .sec-kodawari {
      height: 1123px; } }
  .sec-kodawari h3.title {
    position: absolute;
    top: 0;
    display: block;
    background-repeat: no-repeat;
    background-size: 100% 100%; }
    @media screen and (max-width: 750px) {
      .sec-kodawari h3.title {
        left: 0;
        width: 54%;
        margin: 20.13333% 0 0 5.33333%;
        padding-bottom: 5.6%; } }
    @media screen and (min-width: 751px) {
      .sec-kodawari h3.title {
        right: 0;
        width: 9.07407%;
        margin: 9.25926% 0 0 0;
        padding-bottom: 23.51852%; } }
  .sec-kodawari p.ani-p {
    position: absolute;
    top: 0;
    color: #404040; }
    @media screen and (max-width: 750px) {
      .sec-kodawari p.ani-p {
        left: 0;
        margin: 38.13333% 0 0 5.33333%;
        padding-right: 5.33333%; } }
    @media screen and (min-width: 751px) {
      .sec-kodawari p.ani-p {
        right: 0;
        margin: 12.12963% 14.07407% 0 0; } }
    .sec-kodawari p.ani-p span {
      position: relative; }
      @media screen and (max-width: 750px) {
        .sec-kodawari p.ani-p span {
          display: inline;
          font-size: 3.73333vw;
          line-height: 7.2vw;
          letter-spacing: 0.53333vw; } }
      @media screen and (min-width: 751px) {
        .sec-kodawari p.ani-p span {
          display: block;
          float: right;
          letter-spacing: 2px; } }
      @media screen and (min-width: 751px) and (max-width: 1200px) {
        .sec-kodawari p.ani-p span {
          width: 3.58333vw;
          font-size: 1.33333vw;
          line-height: 3.58333vw;
          -ms-writing-mode: tb-rl;
          writing-mode: vertical-rl;
          text-orientation: mixed; } }
      @media screen and (min-width: 1201px) {
        .sec-kodawari p.ani-p span {
          width: 42px;
          font-size: 16px;
          line-height: 42px;
          -ms-writing-mode: tb-rl;
          writing-mode: vertical-rl;
          text-orientation: mixed; } }
  .sec-kodawari .pic-kodawari {
    position: absolute;
    top: 0;
    display: block;
    background-repeat: no-repeat;
    background-size: 100% 100%; }
    @media screen and (max-width: 750px) {
      .sec-kodawari .pic-kodawari {
        right: 0;
        left: 0;
        width: 89.33333%;
        margin: 72.26667% auto 0;
        padding-bottom: 47.2%;
        background-image: url("../images/pic-kodawari-sp.jpg"); } }
    @media screen and (min-width: 751px) {
      .sec-kodawari .pic-kodawari {
        left: 0;
        width: 39.81481%;
        margin: 10.46296% 0 0 0;
        padding-bottom: 21.11111%;
        background-image: url("../images/pic-kodawari-pc.jpg"); } }
  .sec-kodawari .sec-bg {
    position: absolute;
    right: 0;
    left: 0;
    width: 100%;
    max-width: 1600px;
    margin: 0 auto;
    background-color: #fff; }
    @media screen and (max-width: 750px) {
      .sec-kodawari .sec-bg {
        height: 100%;
        background-image: url("../images/bg-kodawari-sp.jpg");
        background-repeat: no-repeat;
        background-position: 0% 100%;
        background-size: 100% auto; } }
    @media screen and (min-width: 751px) and (max-width: 1200px) {
      .sec-kodawari .sec-bg {
        height: 37.83333vw; } }
    @media screen and (min-width: 1201px) {
      .sec-kodawari .sec-bg {
        height: 454px; } }

.fix-bg {
  position: fixed;
  right: 0;
  left: 0;
  width: 100%;
  max-width: 1600px;
  height: 100%;
  margin: 0 auto;
  background-repeat: no-repeat;
  background-position: 50% 50%;
  background-size: cover; }
  @media screen and (max-width: 750px) {
    .fix-bg {
      display: none; } }
  @media screen and (min-width: 751px) {
    .fix-bg {
      background-image: url("../images/bg-kodawari-pc.jpg"); } }

.sec-akogareruaji {
  background-repeat: no-repeat;
  background-position: 50% 0;
  background-size: auto 100%; }
  @media screen and (max-width: 750px) {
    .sec-akogareruaji {
      height: 152vw;
      background-image: url("../images/bg-akogareruaji-sp.jpg"); } }
  @media screen and (min-width: 751px) {
    .sec-akogareruaji {
      background-image: url("../images/bg-akogareruaji-pc.jpg"); } }
  @media screen and (min-width: 751px) and (max-width: 1200px) {
    .sec-akogareruaji {
      height: 66.66667vw; } }
  @media screen and (min-width: 1201px) {
    .sec-akogareruaji {
      height: 800px; } }
  .sec-akogareruaji h3.title {
    position: absolute;
    top: 0;
    display: block;
    background-repeat: no-repeat;
    background-size: 100% 100%; }
    @media screen and (max-width: 750px) {
      .sec-akogareruaji h3.title {
        left: 0;
        width: 55.6%;
        margin: 29.73333% 0 0 5.33333%;
        padding-bottom: 5.73333%; } }
    @media screen and (min-width: 751px) {
      .sec-akogareruaji h3.title {
        right: 0;
        width: 9.16667%;
        margin: 10.18519% 36.2037% 0 0;
        padding-bottom: 23.05556%; } }
  .sec-akogareruaji p.ani-p {
    position: absolute;
    top: 0;
    color: #fff; }
    @media screen and (max-width: 750px) {
      .sec-akogareruaji p.ani-p {
        left: 0;
        margin: 47.46667% 0 0 5.33333%;
        padding-right: 5.33333%; } }
    @media screen and (min-width: 751px) {
      .sec-akogareruaji p.ani-p {
        right: 0;
        margin: 17.5% 51.66667% 0 0; } }
    .sec-akogareruaji p.ani-p span {
      position: relative; }
      @media screen and (max-width: 750px) {
        .sec-akogareruaji p.ani-p span {
          display: inline;
          font-size: 3.73333vw;
          line-height: 7.2vw;
          letter-spacing: 0.53333vw; } }
      @media screen and (min-width: 751px) {
        .sec-akogareruaji p.ani-p span {
          display: block;
          float: right;
          letter-spacing: 2px; } }
      @media screen and (min-width: 751px) and (max-width: 1200px) {
        .sec-akogareruaji p.ani-p span {
          width: 3.58333vw;
          font-size: 1.33333vw;
          line-height: 3.58333vw;
          -ms-writing-mode: tb-rl;
          writing-mode: vertical-rl;
          text-orientation: mixed; } }
      @media screen and (min-width: 1201px) {
        .sec-akogareruaji p.ani-p span {
          width: 42px;
          font-size: 16px;
          line-height: 42px;
          -ms-writing-mode: tb-rl;
          writing-mode: vertical-rl;
          text-orientation: mixed; } }

.sec-yorokobi {
  background-color: #fff;
  background-repeat: no-repeat;
  background-position: 50% 0;
  background-size: auto 100%; }
  @media screen and (max-width: 750px) {
    .sec-yorokobi {
      height: 383.73333vw; } }
  @media screen and (min-width: 751px) and (max-width: 1200px) {
    .sec-yorokobi {
      height: 93.16667vw; } }
  @media screen and (min-width: 1201px) {
    .sec-yorokobi {
      height: 1118px; } }
  .sec-yorokobi .container {
    height: 100%; }
  .sec-yorokobi .container-in {
    height: 100%; }
  .sec-yorokobi h3.title {
    position: absolute;
    top: 0;
    display: block;
    background-repeat: no-repeat;
    background-size: 100% 100%; }
    @media screen and (max-width: 750px) {
      .sec-yorokobi h3.title {
        left: 0;
        width: 70.66667%;
        margin: 20.13333% 0 0 5.33333%;
        padding-bottom: 26.8%; } }
    @media screen and (min-width: 751px) {
      .sec-yorokobi h3.title {
        right: 0;
        width: 9.16667%;
        margin: 12.22222% 0 0 0;
        padding-bottom: 64.90741%; } }
  .sec-yorokobi .pic-yorokobi-0-0 {
    position: absolute;
    top: 0;
    left: 0;
    background-repeat: no-repeat;
    background-size: 100% 100%; }
    @media screen and (max-width: 750px) {
      .sec-yorokobi .pic-yorokobi-0-0 {
        width: 89.33333%;
        margin: 75.86667% 0 0 5.33333%;
        padding-bottom: 89.46667%;
        background-image: url("../images/pic-yorokobi-0-0-sp.jpg"); } }
    @media screen and (min-width: 751px) {
      .sec-yorokobi .pic-yorokobi-0-0 {
        width: 36.57407%;
        margin: 23.7963% 0 0 46.94444%;
        padding-bottom: 41.38889%;
        background-image: url("../images/pic-yorokobi-0-0-pc.jpg"); } }
  .sec-yorokobi .pic-yorokobi-0-1 {
    position: absolute;
    top: 0;
    left: 0;
    background-repeat: no-repeat;
    background-size: 100% 100%; }
    @media screen and (max-width: 750px) {
      .sec-yorokobi .pic-yorokobi-0-1 {
        width: 40%;
        margin: 148.93333% 0 0 0%;
        padding-bottom: 42.66667%;
        background-image: url("../images/pic-yorokobi-0-1-sp.jpg"); } }
    @media screen and (min-width: 751px) {
      .sec-yorokobi .pic-yorokobi-0-1 {
        width: 21.11111%;
        margin: 56.38889% 0 0 41.2037%;
        padding-bottom: 21.11111%;
        background-image: url("../images/pic-yorokobi-0-1-pc.jpg"); } }
  .sec-yorokobi .pic-yorokobi-0-2 {
    position: absolute;
    top: 0;
    left: 0;
    background-repeat: no-repeat;
    background-size: 100% 100%; }
    @media screen and (max-width: 750px) {
      .sec-yorokobi .pic-yorokobi-0-2 {
        width: 40%;
        margin: 171.6% 0 0 32%;
        padding-bottom: 42.66667%;
        background-image: url("../images/pic-yorokobi-0-2-sp.jpg"); } }
    @media screen and (min-width: 751px) {
      .sec-yorokobi .pic-yorokobi-0-2 {
        width: 21.11111%;
        margin: 68.42593% 0 0 58.7963%;
        padding-bottom: 21.11111%;
        background-image: url("../images/pic-yorokobi-0-2-pc.jpg"); } }
  .sec-yorokobi .pic-yorokobi-1-0 {
    position: absolute;
    top: 0;
    left: 0;
    background-repeat: no-repeat;
    background-size: 100% 100%; }
    @media screen and (max-width: 750px) {
      .sec-yorokobi .pic-yorokobi-1-0 {
        width: 89.33333%;
        margin: 248% 0 0 5.33333%;
        padding-bottom: 116%;
        background-image: url("../images/pic-yorokobi-1-0-sp.jpg"); } }
    @media screen and (min-width: 751px) {
      .sec-yorokobi .pic-yorokobi-1-0 {
        width: 36.57407%;
        margin: 42.87037% 0 0 0%;
        padding-bottom: 47.59259%;
        background-image: url("../images/pic-yorokobi-1-0-pc.jpg"); } }

.obi-yorokobi-box {
  position: relative;
  top: 0;
  left: 0;
  overflow: hidden;
  width: 100%;
  height: auto;
  background-color: #fff; }

.obi-yorokobi {
  position: relative;
  right: 0;
  left: 0;
  width: 100%;
  max-width: 1600px;
  height: 100%;
  margin: 0 auto;
  background-repeat: no-repeat;
  background-position: 50% 0;
  background-size: auto 100%; }
  @media screen and (max-width: 750px) {
    .obi-yorokobi {
      margin-bottom: 19.6%;
      padding-bottom: 26.66667%;
      background-image: url("../images/obi-yorokobi-sp.jpg"); } }
  @media screen and (min-width: 751px) {
    .obi-yorokobi {
      background-image: url("../images/obi-yorokobi-pc.jpg"); } }
  @media screen and (min-width: 751px) and (max-width: 1600px) {
    .obi-yorokobi {
      height: calc( 100vw * ( 180 / 1600 ));
      margin-bottom: calc( 100vw * ( 180 / 1600 )); } }
  @media screen and (min-width: 1601px) {
    .obi-yorokobi {
      height: 180px;
      margin-bottom: 180px; } }

.sec-jelly-and-mousse {
  background-repeat: no-repeat;
  background-position: 50% 0;
  background-size: auto 100%; }
  @media screen and (max-width: 750px) {
    .sec-jelly-and-mousse {
      height: 160vw;
      background-image: url("../images/bg-jelly-and-mousse-sp.jpg"); } }
  @media screen and (min-width: 751px) {
    .sec-jelly-and-mousse {
      background-image: url("../images/bg-jelly-and-mousse-pc.jpg"); } }
  @media screen and (min-width: 751px) and (max-width: 1200px) {
    .sec-jelly-and-mousse {
      height: 45vw; } }
  @media screen and (min-width: 1201px) {
    .sec-jelly-and-mousse {
      height: 540px; } }
  .sec-jelly-and-mousse .jelly-and-mousse-box {
    position: absolute;
    top: 0;
    left: 0;
    background-color: #fff; }
    @media screen and (max-width: 750px) {
      .sec-jelly-and-mousse .jelly-and-mousse-box {
        right: 0;
        width: 89.33333%;
        margin: 66.8% auto 0;
        padding-bottom: 77.6%; } }
    @media screen and (min-width: 751px) {
      .sec-jelly-and-mousse .jelly-and-mousse-box {
        width: 48.14815%;
        padding-bottom: 32.31481%; } }
    .sec-jelly-and-mousse .jelly-and-mousse-box h3.title {
      position: relative;
      top: 0;
      display: block;
      background-repeat: no-repeat;
      background-size: 100% 100%; }
      @media screen and (max-width: 750px) {
        .sec-jelly-and-mousse .jelly-and-mousse-box h3.title {
          right: 0;
          left: 0;
          width: calc( 100% * ( 316 / 670 ));
          margin: calc( 100% * ( 60 / 670 )) 0 0 calc( 100% * ( 60 / 670 ));
          padding-bottom: calc( 100% * ( 49 / 670 ));
          background-image: url("../images/h3-jelly-and-mousse-sp.png"); } }
      @media screen and (min-width: 751px) {
        .sec-jelly-and-mousse .jelly-and-mousse-box h3.title {
          left: 0;
          width: calc( 100% * ( 246 / 520 ));
          margin: calc( 100% * ( 50 / 520 )) 0 0 calc( 100% * ( 47 / 520 ));
          padding-bottom: calc( 100% * ( 37 / 520 ));
          background-image: url("../images/h3-jelly-and-mousse-pc.png"); } }
      .sec-jelly-and-mousse .jelly-and-mousse-box h3.title + span {
        position: relative;
        display: inline-block;
        color: #6fa1ab; }
        @media screen and (max-width: 750px) {
          .sec-jelly-and-mousse .jelly-and-mousse-box h3.title + span {
            margin: 0 0 0 calc( 100% * ( 60 / 670 ));
            font-size: 3.73333vw;
            line-height: 8.66667vw; } }
        @media screen and (min-width: 751px) {
          .sec-jelly-and-mousse .jelly-and-mousse-box h3.title + span {
            margin: 0 0 0 calc( 100% * ( 47 / 520 )); } }
        @media screen and (min-width: 751px) and (max-width: 1200px) {
          .sec-jelly-and-mousse .jelly-and-mousse-box h3.title + span {
            font-size: 1.33333vw;
            line-height: 3.16667vw; } }
        @media screen and (min-width: 1201px) {
          .sec-jelly-and-mousse .jelly-and-mousse-box h3.title + span {
            font-size: 16px;
            line-height: 38px; } }
        .sec-jelly-and-mousse .jelly-and-mousse-box h3.title + span img {
          position: relative;
          vertical-align: middle; }
          @media screen and (max-width: 750px) {
            .sec-jelly-and-mousse .jelly-and-mousse-box h3.title + span img {
              width: calc( 100% * ( 50 / 670 ));
              margin-right: calc( 100% * ( 28 / 670 )); } }
          @media screen and (min-width: 751px) {
            .sec-jelly-and-mousse .jelly-and-mousse-box h3.title + span img {
              width: calc( 100% * ( 69 / 520 ));
              margin-right: calc( 100% * ( 31 / 520 )); } }
    .sec-jelly-and-mousse .jelly-and-mousse-box p {
      position: relative;
      display: inline-block;
      color: #404040; }
      @media screen and (max-width: 750px) {
        .sec-jelly-and-mousse .jelly-and-mousse-box p {
          margin: 0 calc( 100% * ( 60 / 670 ));
          font-size: 3.73333vw;
          line-height: 7.46667vw;
          letter-spacing: 0.53333vw; } }
      @media screen and (min-width: 751px) {
        .sec-jelly-and-mousse .jelly-and-mousse-box p {
          margin: 0 0 0 calc( 100% * ( 47 / 520 )); } }
      @media screen and (min-width: 751px) and (max-width: 1200px) {
        .sec-jelly-and-mousse .jelly-and-mousse-box p {
          font-size: 1.33333vw;
          line-height: 2.66667vw; } }
      @media screen and (min-width: 1201px) {
        .sec-jelly-and-mousse .jelly-and-mousse-box p {
          font-size: 16px;
          line-height: 32px; } }
    .sec-jelly-and-mousse .jelly-and-mousse-box a {
      position: relative;
      display: block;
      box-sizing: border-box;
      color: #404040;
      border: 1px solid #404040; }
      @media screen and (max-width: 750px) {
        .sec-jelly-and-mousse .jelly-and-mousse-box a {
          width: 40vw;
          margin: calc( 100% * ( 35 / 670 )) auto 0;
          padding-left: calc( 100% * ( 88 / 670 ));
          font-size: 3.73333vw;
          line-height: 10.66667vw;
          letter-spacing: 0.53333vw; } }
      @media screen and (min-width: 751px) {
        .sec-jelly-and-mousse .jelly-and-mousse-box a {
          margin: calc( 100% * ( 31 / 520 )) 0 0 calc( 100% * ( 47 / 520 ));
          padding-left: calc( 100% * ( 38 / 520 )); } }
      @media screen and (min-width: 751px) and (max-width: 1200px) {
        .sec-jelly-and-mousse .jelly-and-mousse-box a {
          width: 12.5vw;
          font-size: 1.33333vw;
          line-height: 3.75vw; } }
      @media screen and (min-width: 1201px) {
        .sec-jelly-and-mousse .jelly-and-mousse-box a {
          width: 150px;
          font-size: 16px;
          line-height: 45px; } }
      .sec-jelly-and-mousse .jelly-and-mousse-box a:after {
        content: "";
        position: absolute;
        top: 0;
        right: 0;
        display: inline-block;
        background-image: url("../images/btn-arrow-b.png");
        background-size: 100% 100%; }
        @media screen and (max-width: 750px) {
          .sec-jelly-and-mousse .jelly-and-mousse-box a:after {
            width: calc( 100% * ( 36 / 300 ));
            margin: calc( 100% * ( 33 / 300 )) calc( 100% * ( 26 / 300 )) 0 0;
            padding-bottom: calc( 100% * ( 14 / 300 )); } }
        @media screen and (min-width: 751px) {
          .sec-jelly-and-mousse .jelly-and-mousse-box a:after {
            width: calc( 100% * ( 18 / 150 ));
            margin: calc( 100% * ( 19 / 150 )) calc( 100% * ( 18 / 150 )) 0 0;
            padding-bottom: calc( 100% * ( 7 / 150 )); } }

.sec-shinsyokan {
  background-color: #fff;
  background-repeat: no-repeat;
  background-position: 50% 0;
  background-size: auto 100%; }
  @media screen and (max-width: 750px) {
    .sec-shinsyokan {
      height: 89.06667vw; } }
  @media screen and (min-width: 751px) and (max-width: 1200px) {
    .sec-shinsyokan {
      height: 49.25vw; } }
  @media screen and (min-width: 1201px) {
    .sec-shinsyokan {
      height: 591px; } }
  .sec-shinsyokan h3.title {
    position: absolute;
    top: 0;
    display: block;
    background-repeat: no-repeat;
    background-size: 100% 100%; }
    @media screen and (max-width: 750px) {
      .sec-shinsyokan h3.title {
        left: 0;
        width: 66.26667%;
        margin: 19.86667% 0 0 5.33333%;
        padding-bottom: 5.46667%; } }
    @media screen and (min-width: 751px) {
      .sec-shinsyokan h3.title {
        right: 0;
        width: 9.16667%;
        margin: 8.88889% 0% 0 0;
        padding-bottom: 31.85185%; } }
  .sec-shinsyokan .pic-shinsyokan-0 {
    position: absolute;
    top: 0;
    left: 0;
    display: block;
    background-image: url("../images/pic-shinsyokan-0-pc.png");
    background-repeat: no-repeat;
    background-size: 100% 100%; }
    @media screen and (max-width: 750px) {
      .sec-shinsyokan .pic-shinsyokan-0 {
        width: 28.66667%;
        margin: 40.26667% 0 0 66%;
        padding-bottom: 28.66667%; } }
    @media screen and (min-width: 751px) {
      .sec-shinsyokan .pic-shinsyokan-0 {
        width: 25.09259%;
        margin: 13.33333% 0 0 52.77778%;
        padding-bottom: 25.09259%; } }
  .sec-shinsyokan .pic-shinsyokan-1 {
    position: absolute;
    top: 0;
    left: 0;
    display: block;
    background-image: url("../images/pic-shinsyokan-1-pc.png");
    background-repeat: no-repeat;
    background-size: 100% 100%; }
    @media screen and (max-width: 750px) {
      .sec-shinsyokan .pic-shinsyokan-1 {
        width: 28.66667%;
        margin: 40.26667% 0 0 35.73333%;
        padding-bottom: 28.66667%; } }
    @media screen and (min-width: 751px) {
      .sec-shinsyokan .pic-shinsyokan-1 {
        width: 25.09259%;
        margin: 13.33333% 0 0 26.38889%;
        padding-bottom: 25.09259%; } }
  .sec-shinsyokan .pic-shinsyokan-2 {
    position: absolute;
    top: 0;
    left: 0;
    display: block;
    background-image: url("../images/pic-shinsyokan-2-pc.png");
    background-repeat: no-repeat;
    background-size: 100% 100%; }
    @media screen and (max-width: 750px) {
      .sec-shinsyokan .pic-shinsyokan-2 {
        width: 28.66667%;
        margin: 40.26667% 0 0 5.33333%;
        padding-bottom: 28.66667%; } }
    @media screen and (min-width: 751px) {
      .sec-shinsyokan .pic-shinsyokan-2 {
        width: 25.09259%;
        margin: 13.33333% 0 0 0%;
        padding-bottom: 25.09259%; } }

@media screen and (max-width: 750px) {
  .sec-gift {
    height: 176vw; } }

@media screen and (min-width: 751px) and (max-width: 1200px) {
  .sec-gift {
    height: 87.5vw; } }

@media screen and (min-width: 1201px) {
  .sec-gift {
    height: 1050px; } }

.sec-gift .sec-bg {
  position: absolute;
  right: 0;
  left: 0;
  width: 100%;
  max-width: 1600px;
  height: 100%;
  margin: 0 auto;
  background-color: #e3ebed; }

.sec-gift h3.title {
  position: absolute;
  top: 0;
  display: block;
  background-repeat: no-repeat;
  background-size: 100% 100%; }
  @media screen and (max-width: 750px) {
    .sec-gift h3.title {
      left: 0;
      width: 15.6%;
      margin: 77.06667% 0 0 70.66667%;
      padding-bottom: 64.8%; } }
  @media screen and (min-width: 751px) {
    .sec-gift h3.title {
      right: 0;
      width: 11.2963%;
      margin: 27.96296% 0% 0 0;
      padding-bottom: 46.94444%; } }

.sec-gift .hosoku-w {
  position: absolute;
  top: 0;
  left: 0;
  color: #404040; }
  @media screen and (max-width: 750px) {
    .sec-gift .hosoku-w {
      margin: 154.53333% 0 0 5.33333%;
      font-size: 3.73333vw;
      line-height: 7.2vw;
      letter-spacing: 0.4vw; } }
  @media screen and (min-width: 751px) {
    .sec-gift .hosoku-w {
      display: block;
      margin: 92.12963% 0 0 0;
      letter-spacing: 2px; } }
  @media screen and (min-width: 751px) and (max-width: 1200px) {
    .sec-gift .hosoku-w {
      font-size: 1.33333vw;
      line-height: 1; } }
  @media screen and (min-width: 1201px) {
    .sec-gift .hosoku-w {
      font-size: 16px;
      line-height: 1; } }

.sec-gift .pic-gift {
  position: absolute;
  top: 0;
  left: 0;
  display: block;
  background-repeat: no-repeat;
  background-size: 100% 100%; }
  @media screen and (max-width: 750px) {
    .sec-gift .pic-gift {
      right: 0;
      left: auto;
      width: 94.66667%;
      margin: 98% 0 0 0;
      padding-bottom: 50.66667%;
      background-image: url("../images/pic-gift-sp.jpg"); } }
  @media screen and (min-width: 751px) {
    .sec-gift .pic-gift {
      width: 124.07407%;
      margin: 22.5% 0 0 0;
      padding-bottom: 66.66667%;
      background-image: url("../images/pic-gift-pc.jpg"); } }

@media screen and (max-width: 750px) {
  .sec-gohoubi {
    height: 219.06667vw; } }

@media screen and (min-width: 751px) and (max-width: 1200px) {
  .sec-gohoubi {
    height: 141.58333vw; } }

@media screen and (min-width: 1201px) {
  .sec-gohoubi {
    height: 1699px; } }

.sec-gohoubi .sec-bg {
  position: absolute;
  right: 0;
  left: 0;
  width: 100%;
  max-width: 1600px;
  height: 100%;
  margin: 0 auto;
  background-color: #e3ebed; }

.sec-gohoubi h3.title {
  position: absolute;
  top: 0;
  left: 0;
  display: block;
  background-repeat: no-repeat;
  background-size: 100% 100%; }
  @media screen and (max-width: 750px) {
    .sec-gohoubi h3.title {
      width: 16.4%;
      margin: 9.73333% 0 0 10.53333%;
      padding-bottom: 73.6%; } }
  @media screen and (min-width: 751px) {
    .sec-gohoubi h3.title {
      width: 11.48148%;
      margin: 48.33333% 0 0 0;
      padding-bottom: 46.38889%; } }

.sec-gohoubi .hosoku-w {
  position: absolute;
  top: 0;
  left: 0;
  color: #404040; }
  @media screen and (max-width: 750px) {
    .sec-gohoubi .hosoku-w {
      margin: 125.06667% 0 0 5.33333%;
      font-size: 3.73333vw;
      line-height: 7.2vw;
      letter-spacing: 0.4vw; } }
  @media screen and (min-width: 751px) {
    .sec-gohoubi .hosoku-w {
      display: block;
      margin: 112.22222% 0 0 0;
      letter-spacing: 2px; } }
  @media screen and (min-width: 751px) and (max-width: 1200px) {
    .sec-gohoubi .hosoku-w {
      font-size: 1.33333vw;
      line-height: 1; } }
  @media screen and (min-width: 1201px) {
    .sec-gohoubi .hosoku-w {
      font-size: 16px;
      line-height: 1; } }

.sec-gohoubi .pic-gohoubi-0 {
  position: absolute;
  top: 0;
  left: 0;
  display: block;
  background-repeat: no-repeat;
  background-size: 100% 100%; }
  @media screen and (max-width: 750px) {
    .sec-gohoubi .pic-gohoubi-0 {
      width: 91.2%;
      margin: 44.13333% 0 0 0;
      padding-bottom: 49.06667%;
      background-image: url("../images/pic-gohoubi-0-sp.jpg"); } }
  @media screen and (min-width: 751px) {
    .sec-gohoubi .pic-gohoubi-0 {
      width: 110.18519%;
      margin: 41.94444% 0 0 -24.07407%;
      padding-bottom: 66.66667%;
      background-image: url("../images/pic-gohoubi-0-pc.jpg"); } }

.sec-gohoubi .pic-gohoubi-1 {
  position: absolute;
  top: 0;
  left: 0;
  display: block;
  background-repeat: no-repeat;
  background-size: 100% 100%; }
  @media screen and (max-width: 750px) {
    .sec-gohoubi .pic-gohoubi-1 {
      width: 28%;
      margin: 88% 0 0 66.66667%;
      padding-bottom: 19.06667%;
      background-image: url("../images/pic-gohoubi-1-sp.jpg"); } }
  @media screen and (min-width: 751px) {
    .sec-gohoubi .pic-gohoubi-1 {
      right: 0;
      left: auto;
      width: 31.85185%;
      margin: 11.38889% 0 0 0;
      padding-bottom: 21.75926%;
      background-image: url("../images/pic-gohoubi-1-pc.jpg"); } }

.sec-gohoubi .pic-gohoubi-2 {
  position: absolute;
  top: 0;
  left: 0;
  display: block;
  background-repeat: no-repeat;
  background-size: 100% 100%; }
  @media screen and (max-width: 750px) {
    .sec-gohoubi .pic-gohoubi-2 {
      width: 28%;
      margin: 99.46667% 0 0 44%;
      padding-bottom: 19.2%;
      background-image: url("../images/pic-gohoubi-2-sp.jpg"); } }
  @media screen and (min-width: 751px) {
    .sec-gohoubi .pic-gohoubi-2 {
      width: 31.85185%;
      margin: 24.35185% 0 0 42.12963%;
      padding-bottom: 21.75926%;
      background-image: url("../images/pic-gohoubi-2-pc.jpg"); } }

.sec-gohoubi .online-shop-btn {
  position: absolute;
  right: 0;
  left: 0;
  display: block;
  box-sizing: border-box;
  color: #fff;
  background-color: #404040; }
  @media screen and (max-width: 750px) {
    .sec-gohoubi .online-shop-btn {
      width: 53.33333vw;
      margin: 164.4% auto 0;
      padding-left: 15.46667%;
      font-size: 3.73333vw;
      line-height: 12vw;
      letter-spacing: 0.26667vw; } }
  @media screen and (min-width: 751px) {
    .sec-gohoubi .online-shop-btn {
      margin: 132.22222% auto 0;
      padding-left: 9.81481%; } }
  @media screen and (min-width: 751px) and (max-width: 1200px) {
    .sec-gohoubi .online-shop-btn {
      width: 25vw;
      font-size: 1.66667vw;
      line-height: 5.83333vw; } }
  @media screen and (min-width: 1201px) {
    .sec-gohoubi .online-shop-btn {
      width: 300px;
      font-size: 20px;
      line-height: 70px; } }
  .sec-gohoubi .online-shop-btn:after {
    content: "";
    position: absolute;
    top: 50%;
    right: 0;
    display: inline-block;
    background-image: url("../images/btn-arrow-w.png");
    background-size: 100% 100%; }
    @media screen and (max-width: 750px) {
      .sec-gohoubi .online-shop-btn:after {
        width: calc( 100% * ( 36 / 400 ));
        margin: calc( 100% * ( -5 / 400 )) calc( 100% * ( 44 / 400 )) 0 0;
        padding-bottom: calc( 100% * ( 14 / 400 )); } }
    @media screen and (min-width: 751px) {
      .sec-gohoubi .online-shop-btn:after {
        width: calc( 100% * ( 18 / 300 ));
        margin: calc( 100% * ( -3 / 300 )) calc( 100% * ( 28 / 300 )) 0 0;
        padding-bottom: calc( 100% * ( 7 / 300 )); } }
  .sec-gohoubi .online-shop-btn:before {
    content: "";
    position: absolute;
    top: 50%;
    left: 0;
    display: inline-block;
    background-image: url("../images/btn-cart-icon.png");
    background-size: 100% 100%; }
    @media screen and (max-width: 750px) {
      .sec-gohoubi .online-shop-btn:before {
        width: calc( 100% * ( 33 / 400 ));
        margin: calc( 100% * ( -16 / 400 )) 0 0 calc( 100% * ( 63 / 400 ));
        padding-bottom: calc( 100% * ( 28 / 400 )); } }
    @media screen and (min-width: 751px) {
      .sec-gohoubi .online-shop-btn:before {
        width: calc( 100% * ( 30 / 300 ));
        margin: calc( 100% * ( -15 / 300 )) 0 0 calc( 100% * ( 56 / 300 ));
        padding-bottom: calc( 100% * ( 26 / 300 )); } }

.bottom-door {
  background-repeat: no-repeat;
  background-position: 50% 0;
  background-size: auto 100%; }
  @media screen and (max-width: 750px) {
    .bottom-door {
      height: 46.66667vw;
      background-image: url("../images/bottom-door-sp.jpg"); } }
  @media screen and (min-width: 751px) {
    .bottom-door {
      background-image: url("../images/bottom-door-pc.jpg"); } }
  @media screen and (min-width: 751px) and (max-width: 1200px) {
    .bottom-door {
      height: 41.66667vw; } }
  @media screen and (min-width: 1201px) {
    .bottom-door {
      height: 500px; } }

@media screen and (max-width: 750px) {
  .color-box-1 {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    margin: 234.93333% 0 0 0; } }

.color-item {
  position: absolute;
  top: 0;
  right: 0;
  overflow: hidden;
  font-family: "Harenosora";
  color: #6f9fa9;
  background-repeat: no-repeat;
  background-size: 100% auto; }
  .color-item:before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    display: block;
    width: 100%;
    height: 100%;
    opacity: 0;
    background-color: #fff; }
    .aos-animate .color-item:before {
      opacity: 1; }
  @media screen and (max-width: 750px) {
    .color-item.sp-verti:after {
      transform: translate(0, -101%); }
      .aos-animate .color-item.sp-verti:after {
        transform: translate(0, 100%); }
        .ie .aos-animate .color-item.sp-verti:after {
          transform: translate(0, 1000%); } }
  @media screen and (max-width: 750px) {
    .color-item.sp-hori:after {
      transform: translate(-101%, 0); }
      .aos-animate .color-item.sp-hori:after {
        transform: translate(100%, 0); }
        .ie .aos-animate .color-item.sp-hori:after {
          transform: translate(1000%, 0); } }
  .color-item:after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    display: block;
    width: 100%;
    height: 120%;
    background-color: #6f9fa9; }
    @media screen and (min-width: 751px) {
      .color-item:after {
        transform: translate(0, -100%); } }
    @media screen and (min-width: 751px) {
      .aos-animate .color-item:after {
        transform: translate(0, 100%); }
        .ie .aos-animate .color-item:after {
          transform: translate(0, 1000%); } }
  .color-item .color-in {
    position: relative;
    top: 0;
    left: 0;
    width: 100%;
    height: auto;
    transition: none;
    white-space: pre;
    opacity: 0; }
    .color-item .color-in .dot {
      position: relative;
      top: -0.6em;
      left: 0.6em; }
    .aos-animate .color-item .color-in {
      opacity: 1; }
  @media screen and (max-width: 750px) {
    .color-item.color-yorokobi-0-0 {
      width: 13.2%;
      margin: 62.66667% 56.4% 0 0;
      padding-bottom: 2%; }
      .color-item.color-yorokobi-0-0 .color-in {
        padding: 3.33333vw 0 0 0;
        font-size: 5.86667vw;
        line-height: 13.2vw;
        letter-spacing: 1.73333vw;
        -ms-writing-mode: tb-rl;
        writing-mode: vertical-rl;
        text-orientation: mixed; } }
  @media screen and (min-width: 751px) {
    .color-item.color-yorokobi-0-0 {
      width: 6.38889%;
      margin: 18.42593% 33.98148% 0 0;
      padding-bottom: 1.11111%;
      -ms-writing-mode: tb-rl;
      writing-mode: vertical-rl;
      text-orientation: mixed; } }
  @media screen and (min-width: 751px) and (max-width: 1200px) {
    .color-item.color-yorokobi-0-0 .color-in {
      padding: 1.66667vw 0 0 0;
      font-size: 2.33333vw;
      line-height: 5.83333vw;
      letter-spacing: 1.08333vw; } }
  @media screen and (min-width: 1201px) {
    .color-item.color-yorokobi-0-0 .color-in {
      padding: 20px 0 0 0;
      font-size: 28px;
      line-height: 70px;
      letter-spacing: 13px; } }
  @media screen and (max-width: 750px) {
    .color-item.color-yorokobi-0-1 {
      width: 11.46667%;
      margin: 66.8% 75.06667% 0 0;
      padding-bottom: 2%; }
      .color-item.color-yorokobi-0-1 .color-in {
        padding: 3.33333vw 0 0 0;
        font-size: 3.73333vw;
        line-height: 12vw;
        letter-spacing: 0.93333vw;
        -ms-writing-mode: tb-rl;
        writing-mode: vertical-rl;
        text-orientation: mixed; } }
  @media screen and (min-width: 751px) {
    .color-item.color-yorokobi-0-1 {
      width: 5.09259%;
      margin: 20.27778% 43.14815% 0 0;
      padding-bottom: 1.11111%;
      -ms-writing-mode: tb-rl;
      writing-mode: vertical-rl;
      text-orientation: mixed; } }
  @media screen and (min-width: 751px) and (max-width: 1200px) {
    .color-item.color-yorokobi-0-1 .color-in {
      padding: 1.66667vw 0 0 0;
      font-size: 1.33333vw;
      line-height: 4.83333vw;
      letter-spacing: 0.33333vw; } }
  @media screen and (min-width: 1201px) {
    .color-item.color-yorokobi-0-1 .color-in {
      padding: 20px 0 0 0;
      font-size: 16px;
      line-height: 58px;
      letter-spacing: 4px; } }
  @media screen and (max-width: 750px) {
    .color-item.color-yorokobi-1-0 {
      width: 13.33333%;
      margin: 0% 13.33333% 0 0;
      padding-bottom: 2%; }
      .color-item.color-yorokobi-1-0 .color-in {
        padding: 3.33333vw 0 0 0;
        font-size: 5.86667vw;
        line-height: 13.2vw;
        letter-spacing: 1.73333vw;
        -ms-writing-mode: tb-rl;
        writing-mode: vertical-rl;
        text-orientation: mixed; } }
  @media screen and (min-width: 751px) {
    .color-item.color-yorokobi-1-0 {
      width: 6.38889%;
      margin: 37.31481% 81.11111% 0 0;
      padding-bottom: 1.11111%;
      -ms-writing-mode: tb-rl;
      writing-mode: vertical-rl;
      text-orientation: mixed; } }
  @media screen and (min-width: 751px) and (max-width: 1200px) {
    .color-item.color-yorokobi-1-0 .color-in {
      padding: 1.66667vw 0 0 0;
      font-size: 2.33333vw;
      line-height: 5.83333vw;
      letter-spacing: 1.08333vw; } }
  @media screen and (min-width: 1201px) {
    .color-item.color-yorokobi-1-0 .color-in {
      padding: 20px 0 0 0;
      font-size: 28px;
      line-height: 70px;
      letter-spacing: 13px; } }
  @media screen and (max-width: 750px) {
    .color-item.color-yorokobi-1-1 {
      width: 11.33333%;
      margin: 4.26667% 31.86667% 0 0;
      padding-bottom: 2%; }
      .color-item.color-yorokobi-1-1 .color-in {
        padding: 3.33333vw 0 0 0;
        font-size: 3.73333vw;
        line-height: 12vw;
        letter-spacing: 0.93333vw;
        -ms-writing-mode: tb-rl;
        writing-mode: vertical-rl;
        text-orientation: mixed; } }
  @media screen and (min-width: 751px) {
    .color-item.color-yorokobi-1-1 {
      width: 5.09259%;
      margin: 38.98148% 90.18519% 0 0;
      padding-bottom: 1.11111%;
      -ms-writing-mode: tb-rl;
      writing-mode: vertical-rl;
      text-orientation: mixed; } }
  @media screen and (min-width: 751px) and (max-width: 1200px) {
    .color-item.color-yorokobi-1-1 .color-in {
      padding: 1.66667vw 0 0 0;
      font-size: 1.33333vw;
      line-height: 4.83333vw;
      letter-spacing: 0.33333vw; } }
  @media screen and (min-width: 1201px) {
    .color-item.color-yorokobi-1-1 .color-in {
      padding: 20px 0 0 0;
      font-size: 16px;
      line-height: 58px;
      letter-spacing: 4px; } }
  @media screen and (max-width: 750px) {
    .color-item.color-gift-0-0 {
      right: auto;
      left: 0;
      width: 71.73333%;
      margin: 25.33333% 0 0 0%;
      padding-right: 2%; }
      .color-item.color-gift-0-0 .color-in {
        padding: 0 0 0 4.66667vw;
        font-size: 5.86667vw;
        line-height: 13.2vw;
        letter-spacing: 1.33333vw; } }
  @media screen and (min-width: 751px) {
    .color-item.color-gift-0-0 {
      right: auto;
      left: 0;
      width: 6.01852%;
      margin: 15.83333% 0 0 15.27778%;
      padding-bottom: 1.11111%;
      -ms-writing-mode: tb-rl;
      writing-mode: vertical-rl;
      text-orientation: mixed; } }
  @media screen and (min-width: 751px) and (max-width: 1200px) {
    .color-item.color-gift-0-0 .color-in {
      padding: 1.66667vw 0 0 0;
      font-size: 2.33333vw;
      line-height: 5.83333vw;
      letter-spacing: 0.54167vw; } }
  @media screen and (min-width: 1201px) {
    .color-item.color-gift-0-0 .color-in {
      padding: 20px 0 0 0;
      font-size: 28px;
      line-height: 70px;
      letter-spacing: 6.5px; } }
  .color-item.color-gift-0-1 {
    right: auto;
    left: 0; }
    @media screen and (max-width: 750px) {
      .color-item.color-gift-0-1 {
        width: 86.13333%;
        margin: 44.93333% 0 0 5.33333%;
        padding-right: 2%; }
        .color-item.color-gift-0-1 .color-in {
          padding: 0 0 0 3.33333vw;
          font-size: 5.86667vw;
          line-height: 13.2vw;
          letter-spacing: 1.2vw; } }
    @media screen and (min-width: 751px) {
      .color-item.color-gift-0-1 {
        width: 6.01852%;
        margin: 18.7037% 0 0 6.2963%;
        padding-bottom: 1.11111%;
        -ms-writing-mode: tb-rl;
        writing-mode: vertical-rl;
        text-orientation: mixed; } }
    @media screen and (min-width: 751px) and (max-width: 1200px) {
      .color-item.color-gift-0-1 .color-in {
        padding: 1.66667vw 0 0 0;
        font-size: 2.33333vw;
        line-height: 5.83333vw;
        letter-spacing: 0.54167vw; } }
    @media screen and (min-width: 1201px) {
      .color-item.color-gift-0-1 .color-in {
        padding: 20px 0 0 0;
        font-size: 28px;
        line-height: 70px;
        letter-spacing: 6.5px; } }
  .aos-animate .color-item:nth-of-type(1):before {
    transition: opacity 0.01s 0.5s; }
  .aos-animate .color-item:nth-of-type(1) .color-in {
    transition: opacity 0.01s 0.5s; }
  .aos-animate .color-item:nth-of-type(1):after {
    transition: transform 0.6s 0.2s linear; }
  .aos-animate .color-item:nth-of-type(2):before {
    transition: opacity 0.01s 0.7s; }
  .aos-animate .color-item:nth-of-type(2) .color-in {
    transition: opacity 0.01s 0.7s; }
  .aos-animate .color-item:nth-of-type(2):after {
    transition: transform 0.6s 0.4s linear; }
  .aos-animate .color-item:nth-of-type(3):before {
    transition: opacity 0.01s 0.9s; }
  .aos-animate .color-item:nth-of-type(3) .color-in {
    transition: opacity 0.01s 0.9s; }
  .aos-animate .color-item:nth-of-type(3):after {
    transition: transform 0.6s 0.6s linear; }

.fix-online-shop-btn {
  position: fixed;
  top: 0;
  right: 0;
  background-size: 100% 100%; }
  @media screen and (max-width: 750px) {
    .fix-online-shop-btn {
      width: 17.33333%;
      margin: 0 5.33333% 0 0;
      padding-bottom: 19.86667%;
      background-image: url("../images/online-shop-btn-sp.png"); } }
  @media screen and (min-width: 751px) {
    .fix-online-shop-btn {
      background-image: url("../images/online-shop-btn-pc.png"); } }
  @media screen and (min-width: 751px) and (max-width: 1600px) {
    .fix-online-shop-btn {
      width: calc( 100% * ( 120 / 1600 ));
      margin: 0 calc( 100% * ( 140 / 1600 )) 0 0;
      padding-bottom: calc( 100% * ( 120 / 1600 )); } }
  @media screen and (min-width: 1601px) {
    .fix-online-shop-btn {
      width: 120px;
      margin: 0 140px 0 0;
      padding-bottom: 120px; } }
