@charset "utf-8";

.clearfix {
  @include clearfix();
}

.container {
  @include cwLayout();
  position: relative;
}


.container-in {
  position: relative;
  margin-right: auto;
  margin-left: auto;
  @media screen and (min-width: 751px) {
    max-width: calc( 100% * ( 1080 / #{$contentsW} ) );
  }
}
.pc{
  @media screen and (max-width: 750px) {
    display: none;
  }
}
.sp{
  @media screen and (min-width: 751px) {
    display: none;
  }
}
.full{
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.full-x{
  position: relative;
  top: 0;
  left: 0;
  width: 100%;
  height: auto;
}
.hide-text{
  @include hide-text();
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  pointer-events: none;
}
@font-face {
  font-family: "Harenosora";
  src: url("../fonts/hare-subset.woff2") format("woff2"),
    url("../fonts/hare-subset.woff") format("woff");
}
