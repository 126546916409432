@charset "utf-8";
@import url("https://fonts.googleapis.com/css2?family=Lato&display=swap");
@keyframes intro{
  0%{
    opacity: 0;
  }
  10%{
    transform: scale(1);
    opacity: 1;
  }
  35%{
    transform: scale(1);
    opacity: 1;
  }
  100%{
    transform: scale(1.5);
    opacity: 0;
  }
}
body{
  &.start-intro{
    .fix-w-bg{
      transition: 0.1s 2s;
      opacity: 0;
    }
    .fix-intro{
      animation-name: intro;
      animation-duration: 8s;
      animation-fill-mode: both;
    }
    .door-frame{
      &:before{
        transition: transform 3.8s 0.6s;
        transform: translate(-100%,0);
      }
      &:after{
        transition: transform 3.8s 0.6s;
        transform: translate(100%,0);
      }
    }
  }
}
.wrapper{
  position: relative;
  top: 0;
  left: 0;
  overflow-x: hidden;
  width: 100%;
  height: auto;
}
section{
  position: relative;
  top: 0;
  left: 0;
  width: 100%;
  height: auto;
}
.intro-frame{
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%,-50%);
}
.fix-w-bg{
  position: fixed;
  z-index: 5;
  top: 0;
  left: 0;
  overflow: hidden;
  width: 100%;
  height: 100%;
  pointer-events: none;
  background-color: #fff;
}
.fix-intro{
  position: fixed;
  z-index: 5;
  top: 0;
  left: 0;
  overflow: hidden;
  width: 100%;
  height: 100%;
  pointer-events: none;
  opacity: 0;
  background-color: #fff;
  @media screen and (max-width: 750px) {
    &.fix-intro-pc{
      display: none;
    }
  }
  @media screen and (min-width: 751px){
    &.fix-intro-sp{
      display: none;
    }
  }
  .end-intro &{
    animation: none!important;
    opacity: 0!important;
  }
  &.fix-intro-sp{
    .door-frame{
      position: absolute;
      overflow: hidden;
      width: calc( 100% * ( 487 / 750 ) );
      margin: calc( 100% * ( 426 / 750 ) ) 0 0 calc( 100% * ( 132 / 750 ) );
      padding-bottom: calc( 100% * ( 508 / 750 ) );
      &:before{
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        display: block;
        width: calc( 100% * ( 251 / 487 ) );
        height: 100%;
        background-image: url("../images/tobira/SP/SP_tobira_left.png");
        background-size: 100% 100%;
      }
      &:after{
        content: "";
        position: absolute;
        top: 0;
        right: 0;
        display: block;
        width: calc( 100% * ( 257 / 487 ) );
        height: 100%;
        background-image: url("../images/tobira/SP/SP_tobira_right.png");
        background-size: 100% 100%;
      }
    }
    .intro-frame{
      $width: 750;
      $height: 1334;
      background-image: url("../images/tobira/SP/SP_waku.jpg");
      background-size: 100% 100%;
      @media (min-aspect-ratio: #{$width} / #{$height} ) {
        width: 100vw;
        height: calc( 100vw * ( #{$height} / #{$width} ) );
      }
      @media (max-aspect-ratio: #{$width} / #{$height} ) {
        width: calc( 100vh * ( #{$width} / #{$height} ) );
        height: 100vh;
      }
    }
  }
  &.fix-intro-pc{
    .door-frame{
      position: absolute;
      overflow: hidden;
      width: calc( 100% * ( 487 / 1600 ) );
      margin: calc( 100% * ( 165 / 1600 ) ) 0 0 calc( 100% * ( 558 / 1600 ) );
      padding-bottom: calc( 100% * ( 508 / 1600 ) );
      &:before{
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        display: block;
        width: calc( 100% * ( 251 / 487 ) );
        height: 100%;
        background-image: url("../images/tobira/PC/PC_tobira_left.png");
        background-size: 100% 100%;
      }
      &:after{
        content: "";
        position: absolute;
        top: 0;
        right: 0;
        display: block;
        width: calc( 100% * ( 257 / 487 ) );
        height: 100%;
        background-image: url("../images/tobira/PC/PC_tobira_right.png");
        background-size: 100% 100%;
      }
    }
    .intro-frame{
      $width: 1600;
      $height: 800;
      background-image: url("../images/tobira/PC/PC_waku.jpg");
      background-size: 100% 100%;
      @media (min-aspect-ratio: #{$width} / #{$height} ) {
        width: 100vw;
        height: calc( 100vw * ( #{$height} / #{$width} ) );
      }
      @media (max-aspect-ratio: #{$width} / #{$height} ) {
        width: calc( 100vh * ( #{$width} / #{$height} ) );
        height: 100vh;
      }
    }
  }
}
.sec-kv{
  background-color: #fff;
  @media screen and (max-width: 750px) {
    height: vwSP(1500);
  }
  @media screen and (min-width: 751px){
    height: calc( 100vw * ( 700 / 1600 ) );
  }
}
.kv-carousel-item{
  @media screen and (max-width: 750px) {
    height: vwSP(1500);
  }
  @media screen and (min-width: 751px){
    height: calc( 100vw * ( 700 / 1600 ) );
  }
}
.kv-carousel-pc{
  @media screen and (max-width: 750px) {
    position: absolute;
    pointer-events: none;
    opacity: 0;
  }
  @media screen and (min-width: 751px){
  }
  .kv-carousel-item{
    background-repeat: no-repeat;
    background-position: 50% 0;
    background-size: auto 100%;
    @media screen and (min-width: 751px){
      background-size: cover;
    }
    @for $i from 1 through 7 {
      &[data-pic="0#{$i}"]{
        background-image: url("../images/kv-0#{$i}-pc.jpg");
      }
    }
  }
}
.kv-carousel-sp{
  @media screen and (max-width: 750px) {
  }
  @media screen and (min-width: 751px){
    position: absolute;
    pointer-events: none;
    opacity: 0;
  }
  .kv-carousel-item{
    background-repeat: no-repeat;
    background-position: 0 0;
    background-size: 100% 100%;
    @for $i from 0 through 7 {
      &[data-pic="0#{$i}"]{
        background-image: url("../images/kv-0#{$i}-sp.jpg");
      }
    }
  }
}
[data-aos='fade-up']{
  transform: translate3d(0, 50px, 0);
}
body[data-aos-easing="ease"] [data-aos], [data-aos][data-aos][data-aos-easing="ease"]{
  transition-timing-function: cubic-bezier(.81,.37,.2,.6);
}
p.ani-p{
  > span{
    transition: opacity 0.3s 0s, transform  0.3s 0s;
    transform: translate3d(0, 10px, 0);
    opacity: 0;
  }
  &.aos-animate{
    > span{
      opacity: 1;
      @for $i from 0 through 23 {
        &:nth-of-type(#{$i}) {
          transition: opacity 0.9s #{ 0.5s + $i * 0.02s}, transform 0.9s #{ 0.5s + $i * 0.02s};
          transform: translate3d(0, 0, 0);
        }
      }
    }
  }
}
h3.title{
  font-family: "Harenosora";
  @media screen and (max-width: 750px) {
    font-size: vwSP(44);
    line-height: 1.82;
    letter-spacing: vwSP(11);
    .ie &{
      letter-spacing: vwSP(11/2);
    }
    .edge &{
      letter-spacing: 0!important;
    }
  }
  @media screen and (min-width: 751px) {
    .dot{
      position: relative;
      top: -0.6em;
      left: 0.6em;
      .ie &{
        left: -0.6em;
      }
    }
  }
  @media screen and (min-width: 751px) and (max-width: 1200px) {
    font-size: vwPC(35);
    line-height: vwPC(61);
    letter-spacing: vwPC(8);
    .ie &{
      letter-spacing: vwPC(8/2);
    }
  }
  @media screen and (min-width: 1201px) {
    font-size: 35px;
    line-height: 61px;
    letter-spacing: 8px;
    .ie &{
      letter-spacing: #{0.5 * 8px};
    }
  }
  span{
    transition: opacity 0.3s 0s;
    opacity: 0;
  }
  &.aos-animate{
    span{
      opacity: 1;
      @for $i from 0 through 23 {
        &:nth-of-type(#{$i}) {
          transition: opacity 0.8s #{$i * 0.05s};
        }
      }
    }
  }
  .sec-concept-statement &{
    color: #404040;
    -ms-writing-mode: tb-rl;
    writing-mode: vertical-rl;
    text-orientation: mixed;
    @media screen and (max-width: 750px) {
      letter-spacing: vwSP(9);
      .edge &{
        letter-spacing: 0!important;
      }
    }
  }
  .sec-kakurega & {
    color: #fff;
    @media screen and (max-width: 750px) {
      line-height: 1;
      letter-spacing: vwSP(8);
      .edge &{
        letter-spacing: 0!important;
      }
    }
    @media screen and (min-width: 751px) {
      -ms-writing-mode: tb-rl;
      writing-mode: vertical-rl;
      text-orientation: mixed;
    }
    @media screen and (min-width: 751px) and (max-width: 1200px) {
      line-height: vwPC(64);
    }
    @media screen and (min-width: 1201px) {
      line-height: 64px;
    }
  }
  .sec-kodawari & {
    color: #404040;
    @media screen and (max-width: 750px) {
      line-height: 1;
      letter-spacing: vwSP(6);
      .edge &{
        letter-spacing: 0!important;
      }
    }
    @media screen and (min-width: 751px) {
      -ms-writing-mode: tb-rl;
      writing-mode: vertical-rl;
      text-orientation: mixed;
    }
    @media screen and (min-width: 751px) and (max-width: 1200px) {
      line-height: vwPC(64);
    }
    @media screen and (min-width: 1201px) {
      line-height: 64px;
    }
  }
  .sec-akogareruaji & {
    color: #fff;
    @media screen and (max-width: 750px) {
      line-height: 1;
      letter-spacing: vwSP(8);
      .edge &{
        letter-spacing: 0!important;
      }
    }
    @media screen and (min-width: 751px) {
      -ms-writing-mode: tb-rl;
      writing-mode: vertical-rl;
      text-orientation: mixed;
    }
    @media screen and (min-width: 751px) and (max-width: 1200px) {
      line-height: vwPC(65);
    }
    @media screen and (min-width: 1201px) {
      line-height: 65px;
    }
  }
  .sec-yorokobi & {
    color: #404040;
    @media screen and (max-width: 750px) {
      line-height: 1.8;
      letter-spacing: vwSP(8);
      .edge &{
        letter-spacing: 0!important;
      }
    }
    @media screen and (min-width: 751px) {
      -ms-writing-mode: tb-rl;
      writing-mode: vertical-rl;
      text-orientation: mixed;
    }
    @media screen and (min-width: 751px) and (max-width: 1200px) {
      line-height: vwPC(65);
      letter-spacing: vwPC(10);
    }
    @media screen and (min-width: 1201px) {
      line-height: 65px;
      letter-spacing: 10px;
    }
  }
  .sec-shinsyokan & {
    color: #404040;
    @media screen and (max-width: 750px) {
      line-height: 1;
      letter-spacing: vwSP(5);
      .edge &{
        letter-spacing: 0!important;
      }
    }
    @media screen and (min-width: 751px) {
      -ms-writing-mode: tb-rl;
      writing-mode: vertical-rl;
      text-orientation: mixed;
    }
    @media screen and (min-width: 751px) and (max-width: 1200px) {
      line-height: vwPC(65);
      letter-spacing: vwPC(10);
    }
    @media screen and (min-width: 1201px) {
      line-height: 65px;
      letter-spacing: 10px;
    }
  }
  .sec-gift & {
    color: #fff;
    -ms-writing-mode: tb-rl;
    writing-mode: vertical-rl;
    text-orientation: mixed;
    @media screen and (max-width: 750px) {
      line-height: 1.8;
      letter-spacing: vwSP(9);
      .edge &{
        letter-spacing: -2.01vw!important;
      }
    }
    @media screen and (min-width: 751px) {
    }
    @media screen and (min-width: 751px) and (max-width: 1200px) {
      font-size: vwPC(48);
      line-height: vwPC(84);
    }
    @media screen and (min-width: 1201px) {
      font-size: 48px;
      line-height: 84px;
    }
  }
  .sec-gohoubi & {
    color: #fff;
    -ms-writing-mode: tb-rl;
    writing-mode: vertical-rl;
    text-orientation: mixed;
    @media screen and (max-width: 750px) {
      line-height: 1.8;
      letter-spacing: vwSP(16);
      .edge &{
        letter-spacing: -2.01vw!important;
      }
    }
    @media screen and (min-width: 751px) {
    }
    @media screen and (min-width: 751px) and (max-width: 1200px) {
      font-size: vwPC(48);
      line-height: vwPC(84);
    }
    @media screen and (min-width: 1201px) {
      font-size: 48px;
      line-height: 84px;
    }
  }
  .title-in{
    position: absolute;
    top: 0;
    display: inline-block;
    width: 100%;
    height: 120%;
    white-space: pre;
    @media screen and (max-width: 750px) {
    }
    @media screen and (min-width: 751px) {
    }
    @media screen and (min-width: 751px) and (max-width: 1200px) {
    }
    @media screen and (min-width: 1201px) {
    }
    .sec-concept-statement &{
      @media screen and (max-width: 750px) {
        left: 20%;
      }
      @media screen and (min-width: 751px) {
        left: 20%;
      }
    }
    .sec-kakurega & {
      @media screen and (max-width: 750px) {
      }
      @media screen and (min-width: 751px) {
        left: 15%;
      }
    }
    .sec-kodawari & {
      @media screen and (max-width: 750px) {
      }
      @media screen and (min-width: 751px) {
        left: 15%;
      }
    }
    .sec-akogareruaji & {
      @media screen and (max-width: 750px) {
      }
      @media screen and (min-width: 751px) {
        left: 15%;
      }
    }
    .sec-yorokobi & {
      @media screen and (max-width: 750px) {
        top: -10%;
      }
      @media screen and (min-width: 751px) {
        left: 15%;
      }
    }
    .sec-shinsyokan & {
      @media screen and (max-width: 750px) {
      }
      @media screen and (min-width: 751px) {
        left: 15%;
      }
    }
    .sec-gift & {
      @media screen and (max-width: 750px) {
        left: 17%;
        span {
          &:nth-of-type(1) {
            color: #404040;
          }
          &:nth-of-type(2) {
            color: #404040;
          }
          &:nth-of-type(3) {
            color: #404040;
          }
          &:nth-of-type(7) {
            color: #404040;
          }
        }
      }
      @media screen and (min-width: 751px) {
        left: 15%;
      }
    }
    .sec-gohoubi & {
      @media screen and (max-width: 750px) {
        left: 15%;
        span {
          &:nth-of-type(1) {
            color: #404040;
          }
          &:nth-of-type(2) {
            color: #404040;
          }
          &:nth-of-type(3) {
            color: #404040;
          }
          &:nth-of-type(4) {
            color: #404040;
          }
          &:nth-of-type(5) {
            color: #404040;
          }
          &:nth-of-type(6) {
            color: #404040;
          }
        }
      }
      @media screen and (min-width: 751px) {
        left: 15%;
      }
    }
  }
  .title-indent{
    @media screen and (max-width: 750px) {
    }
    @media screen and (min-width: 751px) {
    }
    @media screen and (min-width: 751px) and (max-width: 1200px) {
    }
    @media screen and (min-width: 1201px) {
    }
    .sec-concept-statement &{
      @media screen and (min-width: 751px) and (max-width: 1200px) {
        margin-top: vwPC(48);
      }
      @media screen and (min-width: 1201px) {
        margin-top: 48px;
      }
    }
    .sec-kakurega &{
      @media screen and (min-width: 751px) and (max-width: 1200px) {
        margin-top: vwPC(83);
      }
      @media screen and (min-width: 1201px) {
        margin-top: 83px;
      }
    }
    .sec-kodawari &{
      @media screen and (min-width: 751px) and (max-width: 1200px) {
        margin-top: vwPC(133);
      }
      @media screen and (min-width: 1201px) {
        margin-top: 133px;
      }
    }
    .sec-akogareruaji &{
      @media screen and (min-width: 751px) and (max-width: 1200px) {
        margin-top: vwPC(84);
      }
      @media screen and (min-width: 1201px) {
        margin-top: 84px;
      }
    }
    .sec-yorokobi &{
      @media screen and (min-width: 751px) and (max-width: 1200px) {
        margin-top: vwPC(110);
      }
      @media screen and (min-width: 1201px) {
        margin-top: 110px;
      }
    }
    .sec-shinsyokan &{
      @media screen and (min-width: 751px) and (max-width: 1200px) {
        margin-top: vwPC(45);
      }
      @media screen and (min-width: 1201px) {
        margin-top: 45px;
      }
    }
    .sec-gift &{
      @media screen and (max-width: 750px) {
        margin-top: vwSP(114);
      }
      @media screen and (min-width: 751px) and (max-width: 1200px) {
        margin-top: vwPC(123);
      }
      @media screen and (min-width: 1201px) {
        margin-top: 123px;
      }
    }
    .sec-gohoubi &{
      @media screen and (max-width: 750px) {
        margin-top: vwSP(140);
      }
      @media screen and (min-width: 751px) and (max-width: 1200px) {
        margin-top: vwPC(117);
      }
      @media screen and (min-width: 1201px) {
        margin-top: 117px;
      }
    }
  }
}
//----- concept-statement -----//
.sec-concept-statement{
  background-repeat: no-repeat;
  background-position: 50% 0;
  background-size: auto 100%;
  @media screen and (max-width: 750px) {
    height: vwSP(1792);
    background-image: url("../images/bg-Concept_statement-sp.jpg");
  }
  @media screen and (min-width: 751px) {
    background-image: url("../images/bg-Concept_statement-pc.jpg");
  }
  @media screen and (min-width: 751px) and (max-width: 1200px) {
    height: vwPC(895);
  }
  @media screen and (min-width: 1201px) {
    height: 895px;
  }
  h2.title{
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    display: block;
    background-repeat: no-repeat;
    background-size: 100% 100%;
    @media screen and (max-width: 750px) {
      width: pSP(567);
      margin: pSP(194) auto 0;
      padding-bottom: pSP(60);
      background-image: url("../images/h2-Concept_statement-sp.png");
    }
    @media screen and (min-width: 751px) {
      width: pPC(472);
      margin: pPC(149) auto 0;
      padding-bottom: pPC(50);
      background-image: url("../images/h2-Concept_statement-pc.png");
    }
  }
  h3.title{
    position: absolute;
    top: 0;
    right: 0;
    display: block;
    background-repeat: no-repeat;
    background-size: 100% 100%;
    @media screen and (max-width: 750px) {
      width: pSP(114);
      margin: pSP(410) pSP(99) 0 0;
      padding-bottom: pSP(402);
      // background-image: url("../images/h3-youkoso-sp.png");
      .dot{
        position: relative;
        top: -0.6em;
        left: 0.6em;
      }
    }
    @media screen and (min-width: 751px) {
      width: pPC(89);
      margin: pPC(248) 0 0 0;
      padding-bottom: pPC(359);
      // background-image: url("../images/h3-youkoso-pc.png");
    }
  }
  p.ani-p{
    position: absolute;
    top: 0;
    color: #404040;
    @media screen and (max-width: 750px) {
      left: 0;
      margin: pSP(871) 0 0 pSP(40);
      padding-right: pSP(40);
    }
    @media screen and (min-width: 751px) {
      right: 0;
      margin: pPC(299) pPC(126) 0 0;
      .quotation-mark-start{
        position: relative;
        display: inline-block;
        transform: rotate(90deg);
        transform-origin: 65% 65%;
        .ie &{
          transform: rotate(0deg);
        }
      }
      .quotation-mark-end{
        position: relative;
        display: inline-block;
        transform: rotate(90deg);
        transform-origin: 55% -35%;
        .ie &{
          transform: rotate(0deg);
        }
      }
    }
    @media screen and (min-width: 751px) and (max-width: 1200px) {
    }
    @media screen and (min-width: 1201px) {
    }
    > span{
      position: relative;
      @media screen and (max-width: 750px) {
        display: inline;
        font-size: vwSP(28);
        line-height: vwSP(54);
        // letter-spacing: vwSP(4);
        letter-spacing: 0;
        .ie &{
          letter-spacing: 0;
        }
      }
      @media screen and (min-width: 751px) {
        display: block;
        float: right;
        letter-spacing: 2px;
        .ie &{
          letter-spacing: 0;
        }
      }
      @media screen and (min-width: 751px) and (max-width: 1200px) {
        width: vwPC(43);
        font-size: vwPC(16);
        line-height: vwPC(43);
        -ms-writing-mode: tb-rl;
        writing-mode: vertical-rl;
        text-orientation: mixed;
      }
      @media screen and (min-width: 1201px) {
        width: 42px;
        font-size: 16px;
        line-height: 42px;
        -ms-writing-mode: tb-rl;
        writing-mode: vertical-rl;
        text-orientation: mixed;
      }
    }
  }
}
.sp-t-r{
  @media screen and (max-width: 750px) {
    display: inline-block!important;
    width: 100%;
    text-align: right;
  }
  @media screen and (min-width: 751px) and (max-width: 1200px) {
    top: vwPC(320);
  }
  @media screen and (min-width: 1201px) {
    top: 320px;
  }
}
//----- kakurega -----//
.sec-kakurega{
  background-repeat: no-repeat;
  background-position: 50% 0;
  background-size: auto 100%;
  @media screen and (max-width: 750px) {
    height: vwSP(1025);
    background-image: url("../images/bg-kakurega-sp.jpg");
  }
  @media screen and (min-width: 751px) {
    background-image: url("../images/bg-kakurega-pc.jpg");
  }
  @media screen and (min-width: 751px) and (max-width: 1200px) {
    height: vwPC(895);
  }
  @media screen and (min-width: 1201px) {
    height: 895px;
  }
  h3.title{
    position: absolute;
    top: 0;
    left: 0;
    display: block;
    background-repeat: no-repeat;
    background-size: 100% 100%;
    @media screen and (max-width: 750px) {
      width: pSP(317);
      margin: pSP(222) 0 0 pSP(40);
      padding-bottom: pSP(43);
      // background-image: url("../images/h3-kakurega-sp.png");
    }
    @media screen and (min-width: 751px) {
      width: pPC(97);
      margin: pPC(137) 0 0 pPC(412);
      padding-bottom: pPC(203);
      // background-image: url("../images/h3-kakurega-pc.png");
    }
  }
  p.ani-p{
    position: absolute;
    top: 0;
    color: #fff;
    @media screen and (max-width: 750px) {
      left: 0;
      margin: pSP(355) 0 0 pSP(40);
      padding-right: pSP(40);
    }
    @media screen and (min-width: 751px) {
      right: 0;
      margin: pPC(217) pPC(735) 0 0;
    }
    @media screen and (min-width: 751px) and (max-width: 1200px) {
    }
    @media screen and (min-width: 1201px) {
    }
    span{
      position: relative;
      @media screen and (max-width: 750px) {
        display: inline;
        font-size: vwSP(28);
        line-height: vwSP(54);
        letter-spacing: vwSP(4);
      }
      @media screen and (min-width: 751px) {
        display: block;
        float: right;
        letter-spacing: 2px;
      }
      @media screen and (min-width: 751px) and (max-width: 1200px) {
        width: vwPC(43);
        font-size: vwPC(16);
        line-height: vwPC(43);
        -ms-writing-mode: tb-rl;
        writing-mode: vertical-rl;
        text-orientation: mixed;
      }
      @media screen and (min-width: 1201px) {
        width: 42px;
        font-size: 16px;
        line-height: 42px;
        -ms-writing-mode: tb-rl;
        writing-mode: vertical-rl;
        text-orientation: mixed;
      }
    }
  }
}
//----- kodawari -----//
.sec-kodawari{
  background-repeat: no-repeat;
  background-position: 50% 0;
  background-size: auto 100%;
  @media screen and (max-width: 750px) {
    height: vwSP(1495);
  }
  @media screen and (min-width: 751px) {
  }
  @media screen and (min-width: 751px) and (max-width: 1200px) {
    height: vwPC(1123);
  }
  @media screen and (min-width: 1201px) {
    height: 1123px;
  }
  h3.title{
    position: absolute;
    top: 0;
    display: block;
    background-repeat: no-repeat;
    background-size: 100% 100%;
    @media screen and (max-width: 750px) {
      left: 0;
      width: pSP(405);
      margin: pSP(151) 0 0 pSP(40);
      padding-bottom: pSP(42);
      // background-image: url("../images/h3-kodawari-sp.png");
    }
    @media screen and (min-width: 751px) {
      right: 0;
      width: pPC(98);
      margin: pPC(100) 0 0 0;
      padding-bottom: pPC(254);
      // background-image: url("../images/h3-kodawari-pc.png");
    }
  }
  p.ani-p{
    position: absolute;
    top: 0;
    color: #404040;
    @media screen and (max-width: 750px) {
      left: 0;
      margin: pSP(286) 0 0 pSP(40);
      padding-right: pSP(40);
    }
    @media screen and (min-width: 751px) {
      right: 0;
      margin: pPC(131) pPC(152) 0 0;
    }
    @media screen and (min-width: 751px) and (max-width: 1200px) {
    }
    @media screen and (min-width: 1201px) {
    }
    span{
      position: relative;
      @media screen and (max-width: 750px) {
        display: inline;
        font-size: vwSP(28);
        line-height: vwSP(54);
        letter-spacing: vwSP(4);
      }
      @media screen and (min-width: 751px) {
        display: block;
        float: right;
        letter-spacing: 2px;
      }
      @media screen and (min-width: 751px) and (max-width: 1200px) {
        width: vwPC(43);
        font-size: vwPC(16);
        line-height: vwPC(43);
        -ms-writing-mode: tb-rl;
        writing-mode: vertical-rl;
        text-orientation: mixed;
      }
      @media screen and (min-width: 1201px) {
        width: 42px;
        font-size: 16px;
        line-height: 42px;
        -ms-writing-mode: tb-rl;
        writing-mode: vertical-rl;
        text-orientation: mixed;
      }
    }
  }
  .pic-kodawari{
    position: absolute;
    top: 0;
    display: block;
    background-repeat: no-repeat;
    background-size: 100% 100%;
    @media screen and (max-width: 750px) {
      right: 0;
      left: 0;
      width: pSP(670);
      margin: pSP(542) auto 0;
      padding-bottom: pSP(354);
      background-image: url("../images/pic-kodawari-sp.jpg");
    }
    @media screen and (min-width: 751px) {
      left: 0;
      width: pPC(430);
      margin: pPC(113) 0 0 0;
      padding-bottom: pPC(228);
      background-image: url("../images/pic-kodawari-pc.jpg");
    }
  }
  .sec-bg{
    position: absolute;
    right: 0;
    left: 0;
    width: 100%;
    max-width: 1600px;
    margin: 0 auto;
    background-color: #fff;
    @media screen and (max-width: 750px) {
      height: 100%;
      background-image: url("../images/bg-kodawari-sp.jpg");
      background-repeat: no-repeat;
      background-position: 0% 100%;
      background-size: 100% auto;
    }
    @media screen and (min-width: 751px) and (max-width: 1200px) {
      height: vwPC(454);
    }
    @media screen and (min-width: 1201px) {
      height: 454px;
    }
  }
}
.fix-bg{
  position: fixed;
  right: 0;
  left: 0;
  width: 100%;
  max-width: 1600px;
  height: 100%;
  margin: 0 auto;
  background-repeat: no-repeat;
  background-position: 50% 50%;
  background-size: cover;
  @media screen and (max-width: 750px) {
    display: none;
  }
  @media screen and (min-width: 751px) {
    background-image: url("../images/bg-kodawari-pc.jpg");
  }
}
//----- akogareruaji -----//
.sec-akogareruaji{
  background-repeat: no-repeat;
  background-position: 50% 0;
  background-size: auto 100%;
  @media screen and (max-width: 750px) {
    height: vwSP(1140);
    background-image: url("../images/bg-akogareruaji-sp.jpg");
  }
  @media screen and (min-width: 751px) {
    background-image: url("../images/bg-akogareruaji-pc.jpg");
  }
  @media screen and (min-width: 751px) and (max-width: 1200px) {
    height: vwPC(800);
  }
  @media screen and (min-width: 1201px) {
    height: 800px;
  }
  h3.title{
    position: absolute;
    top: 0;
    display: block;
    background-repeat: no-repeat;
    background-size: 100% 100%;
    @media screen and (max-width: 750px) {
      left: 0;
      width: pSP(417);
      margin: pSP(223) 0 0 pSP(40);
      padding-bottom: pSP(43);
      // background-image: url("../images/h3-akogareruaji-sp.png");
    }
    @media screen and (min-width: 751px) {
      right: 0;
      width: pPC(99);
      margin: pPC(110) pPC(391) 0 0;
      padding-bottom: pPC(249);
      // background-image: url("../images/h3-akogareruaji-pc.png");
    }
  }
  p.ani-p{
    position: absolute;
    top: 0;
    color: #fff;
    @media screen and (max-width: 750px) {
      left: 0;
      margin: pSP(356) 0 0 pSP(40);
      padding-right: pSP(40);
    }
    @media screen and (min-width: 751px) {
      right: 0;
      margin: pPC(189) pPC(558) 0 0;
    }
    @media screen and (min-width: 751px) and (max-width: 1200px) {
    }
    @media screen and (min-width: 1201px) {
    }
    span{
      position: relative;
      @media screen and (max-width: 750px) {
        display: inline;
        font-size: vwSP(28);
        line-height: vwSP(54);
        letter-spacing: vwSP(4);
      }
      @media screen and (min-width: 751px) {
        display: block;
        float: right;
        letter-spacing: 2px;
      }
      @media screen and (min-width: 751px) and (max-width: 1200px) {
        width: vwPC(43);
        font-size: vwPC(16);
        line-height: vwPC(43);
        -ms-writing-mode: tb-rl;
        writing-mode: vertical-rl;
        text-orientation: mixed;
      }
      @media screen and (min-width: 1201px) {
        width: 42px;
        font-size: 16px;
        line-height: 42px;
        -ms-writing-mode: tb-rl;
        writing-mode: vertical-rl;
        text-orientation: mixed;
      }
    }
  }
}
//----- yorokobi -----//
.sec-yorokobi{
  background-color: #fff;
  background-repeat: no-repeat;
  background-position: 50% 0;
  background-size: auto 100%;
  @media screen and (max-width: 750px) {
    height: vwSP(2878);
  }
  @media screen and (min-width: 751px) {
  }
  @media screen and (min-width: 751px) and (max-width: 1200px) {
    height: vwPC(1118);
  }
  @media screen and (min-width: 1201px) {
    height: 1118px;
  }
  .container{
    height: 100%;
  }
  .container-in{
    height: 100%;
  }
  h3.title{
    position: absolute;
    top: 0;
    display: block;
    background-repeat: no-repeat;
    background-size: 100% 100%;
    @media screen and (max-width: 750px) {
      left: 0;
      width: pSP(530);
      margin: pSP(151) 0 0 pSP(40);
      padding-bottom: pSP(201);
      // background-image: url("../images/h3-yorokobi-sp.png");
    }
    @media screen and (min-width: 751px) {
      right: 0;
      width: pPC(99);
      margin: pPC(132) 0 0 0;
      padding-bottom: pPC(701);
      // background-image: url("../images/h3-yorokobi-pc.png");
    }
  }
  .pic-yorokobi-0-0{
    position: absolute;
    top: 0;
    left: 0;
    background-repeat: no-repeat;
    background-size: 100% 100%;
    @media screen and (max-width: 750px) {
      width: pSP(670);
      margin: pSP(569) 0 0 pSP(40);
      padding-bottom: pSP(671);
      background-image: url("../images/pic-yorokobi-0-0-sp.jpg");
    }
    @media screen and (min-width: 751px) {
      width: pPC(395);
      margin: pPC(257) 0 0 pPC(507);
      padding-bottom: pPC(447);
      background-image: url("../images/pic-yorokobi-0-0-pc.jpg");
    }
  }
  .pic-yorokobi-0-1{
    position: absolute;
    top: 0;
    left: 0;
    background-repeat: no-repeat;
    background-size: 100% 100%;
    @media screen and (max-width: 750px) {
      width: pSP(300);
      margin: pSP(1117) 0 0 pSP(0);
      padding-bottom: pSP(320);
      background-image: url("../images/pic-yorokobi-0-1-sp.jpg");
    }
    @media screen and (min-width: 751px) {
      width: pPC(228);
      margin: pPC(609) 0 0 pPC(445);
      padding-bottom: pPC(228);
      background-image: url("../images/pic-yorokobi-0-1-pc.jpg");
    }
  }
  .pic-yorokobi-0-2{
    position: absolute;
    top: 0;
    left: 0;
    background-repeat: no-repeat;
    background-size: 100% 100%;
    @media screen and (max-width: 750px) {
      width: pSP(300);
      margin: pSP(1287) 0 0 pSP(240);
      padding-bottom: pSP(320);
      background-image: url("../images/pic-yorokobi-0-2-sp.jpg");
    }
    @media screen and (min-width: 751px) {
      width: pPC(228);
      margin: pPC(739) 0 0 pPC(635);
      padding-bottom: pPC(228);
      background-image: url("../images/pic-yorokobi-0-2-pc.jpg");
    }
  }
  .pic-yorokobi-1-0{
    position: absolute;
    top: 0;
    left: 0;
    background-repeat: no-repeat;
    background-size: 100% 100%;
    @media screen and (max-width: 750px) {
      width: pSP(670);
      margin: pSP(1860) 0 0 pSP(40);
      padding-bottom: pSP(870);
      background-image: url("../images/pic-yorokobi-1-0-sp.jpg");
    }
    @media screen and (min-width: 751px) {
      width: pPC(395);
      margin: pPC(463) 0 0 pPC(0);
      padding-bottom: pPC(514);
      background-image: url("../images/pic-yorokobi-1-0-pc.jpg");
    }
  }
}
.obi-yorokobi-box{
  position: relative;
  top: 0;
  left: 0;
  overflow: hidden;
  width: 100%;
  height: auto;
  background-color: #fff;
}
.obi-yorokobi{
  position: relative;
  right: 0;
  left: 0;
  width: 100%;
  max-width: 1600px;
  height: 100%;
  margin: 0 auto;
  background-repeat: no-repeat;
  background-position: 50% 0;
  background-size: auto 100%;
  @media screen and (max-width: 750px) {
    margin-bottom: pSP(147);
    padding-bottom: pSP(200);
    background-image: url("../images/obi-yorokobi-sp.jpg");
  }
  @media screen and (min-width: 751px) {
    background-image: url("../images/obi-yorokobi-pc.jpg");
  }
  @media screen and (min-width: 751px) and (max-width: 1600px) {
    height: calc( 100vw * ( 180 / 1600 ) );
    margin-bottom: calc( 100vw * ( 180 / 1600 ) );
  }
  @media screen and (min-width: 1601px) {
    height: 180px;
    margin-bottom: 180px;
  }
}
//----- jelly-and-mousse -----//
.sec-jelly-and-mousse{
  background-repeat: no-repeat;
  background-position: 50% 0;
  background-size: auto 100%;
  @media screen and (max-width: 750px) {
    height: vwSP(1200);
    background-image: url("../images/bg-jelly-and-mousse-sp.jpg");
  }
  @media screen and (min-width: 751px) {
    background-image: url("../images/bg-jelly-and-mousse-pc.jpg");
  }
  @media screen and (min-width: 751px) and (max-width: 1200px) {
    height: vwPC(540);
  }
  @media screen and (min-width: 1201px) {
    height: 540px;
  }
  .jelly-and-mousse-box{
    position: absolute;
    top: 0;
    left: 0;
    background-color: #fff;
    @media screen and (max-width: 750px) {
      right: 0;
      width: pSP(670);
      margin: pSP(501) auto 0;
      padding-bottom: pSP(582);
    }
    @media screen and (min-width: 751px) {
      width: pPC(520);
      padding-bottom: pPC(349);
    }
    h3.title{
      position: relative;
      top: 0;
      display: block;
      background-repeat: no-repeat;
      background-size: 100% 100%;
      @media screen and (max-width: 750px) {
        right: 0;
        left: 0;
        width: calc( 100% * ( 316 / 670 ) );
        margin: calc( 100% * ( 60 / 670 ) ) 0 0 calc( 100% * ( 60 / 670 ) );
        padding-bottom: calc( 100% * ( 49 / 670 ) );
        background-image: url("../images/h3-jelly-and-mousse-sp.png");
      }
      @media screen and (min-width: 751px) {
        left: 0;
        width: calc( 100% * ( 246 / 520 ) );
        margin: calc( 100% * ( 50 / 520 ) ) 0 0 calc( 100% * ( 47 / 520 ) );
        padding-bottom: calc( 100% * ( 37 / 520 ) );
        background-image: url("../images/h3-jelly-and-mousse-pc.png");
      }
      + span {
        position: relative;
        display: inline-block;
        color: #6fa1ab;
        @media screen and (max-width: 750px) {
          margin: 0 0 0 calc( 100% * ( 60 / 670 ) );
          font-size: vwSP(28);
          line-height: vwSP(65);
        }
        @media screen and (min-width: 751px) {
          margin: 0 0 0 calc( 100% * ( 47 / 520 ) );
        }
        @media screen and (min-width: 751px) and (max-width: 1200px) {
          font-size: vwPC(16);
          line-height: vwPC(38);
        }
        @media screen and (min-width: 1201px) {
          font-size: 16px;
          line-height: 38px;
        }
        img{
          position: relative;
          vertical-align: middle;
          @media screen and (max-width: 750px) {
            width: calc( 100% * ( 50 / 670 ) );
            margin-right: calc( 100% * ( 28 / 670 ) );
          }
          @media screen and (min-width: 751px) {
            width: calc( 100% * ( 69 / 520 ) );
            margin-right: calc( 100% * ( 31 / 520 ) );
          }
        }
      }
    }
    p{
      position: relative;
      display: inline-block;
      color: #404040;
      @media screen and (max-width: 750px) {
        margin: 0 calc( 100% * ( 60 / 670 ) );
        font-size: vwSP(28);
        line-height: vwSP(56);
        letter-spacing: vwSP(4);
      }
      @media screen and (min-width: 751px) {
        margin: 0 0 0 calc( 100% * ( 47 / 520 ) );
      }
      @media screen and (min-width: 751px) and (max-width: 1200px) {
        font-size: vwPC(16);
        line-height: vwPC(32);
      }
      @media screen and (min-width: 1201px) {
        font-size: 16px;
        line-height: 32px;
      }
    }
    a{
      position: relative;
      display: block;
      box-sizing: border-box;
      color: #404040;
      border: 1px solid #404040;
      @media screen and (max-width: 750px) {
        width: vwSP(300);
        margin: calc( 100% * ( 35 / 670 ) ) auto 0;
        padding-left: calc( 100% * ( 88 / 670 ) );
        font-size: vwSP(28);
        line-height: vwSP(80);
        letter-spacing: vwSP(4);
      }
      @media screen and (min-width: 751px) {
        margin: calc( 100% * ( 31 / 520 ) ) 0 0 calc( 100% * ( 47 / 520 ) );
        padding-left: calc( 100% * ( 38 / 520 ) );
      }
      @media screen and (min-width: 751px) and (max-width: 1200px) {
        width: vwPC(150);
        font-size: vwPC(16);
        line-height: vwPC(45);
      }
      @media screen and (min-width: 1201px) {
        width: 150px;
        font-size: 16px;
        line-height: 45px;
      }
      &:after{
        content: "";
        position: absolute;
        top: 0;
        right: 0;
        display: inline-block;
        background-image: url("../images/btn-arrow-b.png");
        background-size: 100% 100%;
        @media screen and (max-width: 750px) {
          width: calc( 100% * ( 36 / 300 ) );
          margin: calc( 100% * ( 33 / 300 ) )  calc( 100% * ( 26 / 300 ) ) 0 0;
          padding-bottom: calc( 100% * ( 14 / 300 ) );
        }
        @media screen and (min-width: 751px) {
          width: calc( 100% * ( 18 / 150 ) );
          margin: calc( 100% * ( 19 / 150 ) )  calc( 100% * ( 18 / 150 ) ) 0 0;
          padding-bottom: calc( 100% * ( 7 / 150 ) );
        }
      }
    }
  }
}
//----- shinsyokan -----//
.sec-shinsyokan{
  background-color: #fff;
  background-repeat: no-repeat;
  background-position: 50% 0;
  background-size: auto 100%;
  @media screen and (max-width: 750px) {
    height: vwSP(668);
  }
  @media screen and (min-width: 751px) {
  }
  @media screen and (min-width: 751px) and (max-width: 1200px) {
    height: vwPC(591);
  }
  @media screen and (min-width: 1201px) {
    height: 591px;
  }
  h3.title{
    position: absolute;
    top: 0;
    display: block;
    background-repeat: no-repeat;
    background-size: 100% 100%;
    @media screen and (max-width: 750px) {
      left: 0;
      width: pSP(497);
      margin: pSP(149) 0 0 pSP(40);
      padding-bottom: pSP(41);
      // background-image: url("../images/h3-shinsyokan-sp.png");
    }
    @media screen and (min-width: 751px) {
      right: 0;
      width: pPC(99);
      margin: pPC(96) pPC(0) 0 0;
      padding-bottom: pPC(344);
      // background-image: url("../images/h3-shinsyokan-pc.png");
    }
  }
  .pic-shinsyokan-0{
    position: absolute;
    top: 0;
    left: 0;
    display: block;
    background-image: url("../images/pic-shinsyokan-0-pc.png");
    background-repeat: no-repeat;
    background-size: 100% 100%;
    @media screen and (max-width: 750px) {
      width: pSP(215);
      margin: pSP(302) 0 0 pSP(495);
      padding-bottom: pSP(215);
    }
    @media screen and (min-width: 751px) {
      width: pPC(271);
      margin: pPC(144) 0 0 pPC(570);
      padding-bottom: pPC(271);
    }
  }
  .pic-shinsyokan-1{
    position: absolute;
    top: 0;
    left: 0;
    display: block;
    background-image: url("../images/pic-shinsyokan-1-pc.png");
    background-repeat: no-repeat;
    background-size: 100% 100%;
    @media screen and (max-width: 750px) {
      width: pSP(215);
      margin: pSP(302) 0 0 pSP(268);
      padding-bottom: pSP(215);
    }
    @media screen and (min-width: 751px) {
      width: pPC(271);
      margin: pPC(144) 0 0 pPC(285);
      padding-bottom: pPC(271);
    }
  }
  .pic-shinsyokan-2{
    position: absolute;
    top: 0;
    left: 0;
    display: block;
    background-image: url("../images/pic-shinsyokan-2-pc.png");
    background-repeat: no-repeat;
    background-size: 100% 100%;
    @media screen and (max-width: 750px) {
      width: pSP(215);
      margin: pSP(302) 0 0 pSP(40);
      padding-bottom: pSP(215);
    }
    @media screen and (min-width: 751px) {
      width: pPC(271);
      margin: pPC(144) 0 0 pPC(0);
      padding-bottom: pPC(271);
    }
  }
}
//----- gift -----//
.sec-gift{
  @media screen and (max-width: 750px) {
    height: vwSP(1320);
  }
  @media screen and (min-width: 751px) {
  }
  @media screen and (min-width: 751px) and (max-width: 1200px) {
    height: vwPC(1050);
  }
  @media screen and (min-width: 1201px) {
    height: 1050px;
  }
  .sec-bg{
    position: absolute;
    right: 0;
    left: 0;
    width: 100%;
    max-width: 1600px;
    height: 100%;
    margin: 0 auto;
    background-color: #e3ebed;
  }
  h3.title{
    position: absolute;
    top: 0;
    display: block;
    background-repeat: no-repeat;
    background-size: 100% 100%;
    @media screen and (max-width: 750px) {
      left: 0;
      width: pSP(117);
      margin: pSP(578) 0 0 pSP(530);
      padding-bottom: pSP(486);
      // background-image: url("../images/h3-gift-sp.png");
    }
    @media screen and (min-width: 751px) {
      right: 0;
      width: pPC(122);
      margin: pPC(302) pPC(0) 0 0;
      padding-bottom: pPC(507);
      // background-image: url("../images/h3-gift-pc.png");
    }
  }
  .hosoku-w{
    position: absolute;
    top: 0;
    left: 0;
    color: #404040;
    @media screen and (max-width: 750px) {
      margin: pSP(1159) 0 0 pSP(40);
      font-size: vwSP(28);
      line-height: vwSP(54);
      letter-spacing: vwSP(3);
    }
    @media screen and (min-width: 751px) {
      display: block;
      margin: pPC(995) 0 0 0;
      letter-spacing: 2px;
    }
    @media screen and (min-width: 751px) and (max-width: 1200px) {
      font-size: vwPC(16);
      line-height: 1;
    }
    @media screen and (min-width: 1201px) {
      font-size: 16px;
      line-height: 1;
    }
  }
  .pic-gift{
    position: absolute;
    top: 0;
    left: 0;
    display: block;
    background-repeat: no-repeat;
    background-size: 100% 100%;
    @media screen and (max-width: 750px) {
      right: 0;
      left: auto;
      width: pSP(710);
      margin: pSP(735) 0 0 0;
      padding-bottom: pSP(380);
      background-image: url("../images/pic-gift-sp.jpg");
    }
    @media screen and (min-width: 751px) {
      width: pPC(1340);
      margin: pPC(243) 0 0 0;
      padding-bottom: pPC(720);
      background-image: url("../images/pic-gift-pc.jpg");
    }
  }
}
//----- gohoubi -----//
.sec-gohoubi{
  @media screen and (max-width: 750px) {
    height: vwSP(1643);
  }
  @media screen and (min-width: 751px) {
  }
  @media screen and (min-width: 751px) and (max-width: 1200px) {
    height: vwPC(1699);
  }
  @media screen and (min-width: 1201px) {
    height: 1699px;
  }
  .sec-bg{
    position: absolute;
    right: 0;
    left: 0;
    width: 100%;
    max-width: 1600px;
    height: 100%;
    margin: 0 auto;
    background-color: #e3ebed;
  }
  h3.title{
    position: absolute;
    top: 0;
    left: 0;
    display: block;
    background-repeat: no-repeat;
    background-size: 100% 100%;
    @media screen and (max-width: 750px) {
      width: pSP(123);
      margin: pSP(73) 0 0 pSP(79);
      padding-bottom: pSP(552);
      // background-image: url("../images/h3-gohoubi-sp.png");
    }
    @media screen and (min-width: 751px) {
      width: pPC(124);
      margin: pPC(522) 0 0 0;
      padding-bottom: pPC(501);
      // background-image: url("../images/h3-gohoubi-pc.png");
    }
  }
  .hosoku-w{
    position: absolute;
    top: 0;
    left: 0;
    color: #404040;
    @media screen and (max-width: 750px) {
      margin: pSP(938) 0 0 pSP(40);
      font-size: vwSP(28);
      line-height: vwSP(54);
      letter-spacing: vwSP(3);
    }
    @media screen and (min-width: 751px) {
      display: block;
      margin: pPC(1212) 0 0 0;
      letter-spacing: 2px;
    }
    @media screen and (min-width: 751px) and (max-width: 1200px) {
      font-size: vwPC(16);
      line-height: 1;
    }
    @media screen and (min-width: 1201px) {
      font-size: 16px;
      line-height: 1;
    }
  }
  .pic-gohoubi-0{
    position: absolute;
    top: 0;
    left: 0;
    display: block;
    background-repeat: no-repeat;
    background-size: 100% 100%;
    @media screen and (max-width: 750px) {
      width: pSP(684);
      margin: pSP(331) 0 0 0;
      padding-bottom: pSP(368);
      background-image: url("../images/pic-gohoubi-0-sp.jpg");
    }
    @media screen and (min-width: 751px) {
      width: pPC(1190);
      margin: pPC(453) 0 0 pPC(-260);
      padding-bottom: pPC(720);
      background-image: url("../images/pic-gohoubi-0-pc.jpg");
    }
  }
  .pic-gohoubi-1{
    position: absolute;
    top: 0;
    left: 0;
    display: block;
    background-repeat: no-repeat;
    background-size: 100% 100%;
    @media screen and (max-width: 750px) {
      width: pSP(210);
      margin: pSP(660) 0 0 pSP(500);
      padding-bottom: pSP(143);
      background-image: url("../images/pic-gohoubi-1-sp.jpg");
    }
    @media screen and (min-width: 751px) {
      right: 0;
      left: auto;
      width: pPC(344);
      margin: pPC(123) 0 0 0;
      padding-bottom: pPC(235);
      background-image: url("../images/pic-gohoubi-1-pc.jpg");
    }
  }
  .pic-gohoubi-2{
    position: absolute;
    top: 0;
    left: 0;
    display: block;
    background-repeat: no-repeat;
    background-size: 100% 100%;
    @media screen and (max-width: 750px) {
      width: pSP(210);
      margin: pSP(746) 0 0 pSP(330);
      padding-bottom: pSP(144);
      background-image: url("../images/pic-gohoubi-2-sp.jpg");
    }
    @media screen and (min-width: 751px) {
      width: pPC(344);
      margin: pPC(263) 0 0 pPC(455);
      padding-bottom: pPC(235);
      background-image: url("../images/pic-gohoubi-2-pc.jpg");
    }
  }
  .online-shop-btn{
    position: absolute;
    right: 0;
    left: 0;
    display: block;
    box-sizing: border-box;
    color: #fff;
    background-color: #404040;
    @media screen and (max-width: 750px) {
      width: vwSP(400);
      margin: pSP(1233) auto 0;
      padding-left: pSP(116);
      font-size: vwSP(28);
      line-height: vwSP(90);
      letter-spacing: vwSP(2);
    }
    @media screen and (min-width: 751px) {
      margin: pPC(1428) auto 0;
      padding-left: pPC(106);
    }
    @media screen and (min-width: 751px) and (max-width: 1200px) {
      width: vwPC(300);
      font-size: vwPC(20);
      line-height: vwPC(70);
    }
    @media screen and (min-width: 1201px) {
      width: 300px;
      font-size: 20px;
      line-height: 70px;
    }
    &:after{
      content: "";
      position: absolute;
      top: 50%;
      right: 0;
      display: inline-block;
      background-image: url("../images/btn-arrow-w.png");
      background-size: 100% 100%;
      @media screen and (max-width: 750px) {
        width: calc( 100% * ( 36 / 400 ) );
        margin: calc( 100% * ( -5 / 400 ) ) calc( 100% * ( 44 / 400 ) ) 0 0;
        padding-bottom: calc( 100% * ( 14 / 400 ) );
      }
      @media screen and (min-width: 751px) {
        width: calc( 100% * ( 18 / 300 ) );
        margin: calc( 100% * ( -3 / 300 ) ) calc( 100% * ( 28 / 300 ) ) 0 0;
        padding-bottom: calc( 100% * ( 7 / 300 ) );
      }
    }
    &:before{
      content: "";
      position: absolute;
      top: 50%;
      left: 0;
      display: inline-block;
      background-image: url("../images/btn-cart-icon.png");
      background-size: 100% 100%;
      @media screen and (max-width: 750px) {
        width: calc( 100% * ( 33 / 400 ) );
        margin: calc( 100% * ( -16 / 400 ) ) 0 0 calc( 100% * ( 63 / 400 ) );
        padding-bottom: calc( 100% * ( 28 / 400 ) );
      }
      @media screen and (min-width: 751px) {
        width: calc( 100% * ( 30 / 300 ) );
        margin: calc( 100% * ( -15 / 300 ) ) 0 0 calc( 100% * ( 56 / 300 ) );
        padding-bottom: calc( 100% * ( 26 / 300 ) );
      }
    }
  }
}
.bottom-door{
  background-repeat: no-repeat;
  background-position: 50% 0;
  background-size: auto 100%;
  @media screen and (max-width: 750px) {
    height: vwSP(350);
    background-image: url("../images/bottom-door-sp.jpg");
  }
  @media screen and (min-width: 751px) {
    background-image: url("../images/bottom-door-pc.jpg");
  }
  @media screen and (min-width: 751px) and (max-width: 1200px) {
    height: vwPC(500);
  }
  @media screen and (min-width: 1201px) {
    height: 500px;
  }
}
.color-box-1{
  @media screen and (max-width: 750px) {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    margin: pSP(1762) 0 0 0;
  }
}
$ani_s: 0.6s;
.color-item{
  position: absolute;
  top: 0;
  right: 0;
  overflow: hidden;
  font-family: "Harenosora";
  color: #6f9fa9;
  background-repeat: no-repeat;
  background-size: 100% auto;
  &:before{
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    display: block;
    width: 100%;
    height: 100%;
    opacity: 0;
    background-color: #fff;
    .aos-animate &{
      opacity: 1;
    }
  }

  &.sp-verti{
    &:after{
      @media screen and (max-width: 750px) {
        transform: translate(0, -101%);
        .aos-animate &{
          transform: translate(0, 100%);
          .ie &{
            transform: translate(0, 1000%);
          }
        }
      }
    }
  }
  &.sp-hori{
    &:after{
      @media screen and (max-width: 750px) {
        transform: translate(-101%, 0);
        .aos-animate &{
          transform: translate(100%, 0);
          .ie &{
            transform: translate(1000%,0);
          }
        }
      }
    }
  }
  &:after{
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    display: block;
    width: 100%;
    height: 120%;
    background-color: #6f9fa9;
    @media screen and (min-width: 751px) {
      transform: translate(0, -100%);
    }
    .aos-animate &{
      @media screen and (max-width: 750px) {

      }
      @media screen and (min-width: 751px) {
        transform: translate(0, 100%);
        .ie &{
          transform: translate(0, 1000%);
        }
      }
    }
  }
  .color-in{
    position: relative;
    top: 0;
    left: 0;
    width: 100%;
    height: auto;
    transition: none;
    white-space: pre;
    opacity: 0;
    .dot{
      position: relative;
      top: -0.6em;
      left: 0.6em;
    }
    .aos-animate &{
      opacity: 1;
    }
  }
  &.color-yorokobi-0-0{
    @media screen and (max-width: 750px) {
      width: pSP(99);
      margin: pSP(470) pSP(423) 0 0;
      // padding-bottom: pSP(154);
      padding-bottom: pSP(15);
      // background-image: url("../images/color-yorokobi-0-0-sp.jpg");
      .color-in{
        padding: vwSP(25) 0 0 0;
        font-size: vwSP(44);
        line-height: vwSP(99);
        letter-spacing: vwSP(13);
        -ms-writing-mode: tb-rl;
        writing-mode: vertical-rl;
        text-orientation: mixed;
      }
    }
    @media screen and (min-width: 751px) {
      width: pPC(69);
      margin: pPC(199) pPC(367) 0 0;
      // padding-bottom: pPC(110);
      padding-bottom: pPC(12);
      -ms-writing-mode: tb-rl;
      writing-mode: vertical-rl;
      text-orientation: mixed;
      // background-image: url("../images/color-yorokobi-0-0-pc.jpg");
    }
    @media screen and (min-width: 751px) and (max-width: 1200px) {
      .color-in{
        padding: vwPC(20) 0 0 0;
        font-size: vwPC(28);
        line-height: vwPC(70);
        letter-spacing: vwPC(13);
      }
    }
    @media screen and (min-width: 1201px) {
      .color-in{
        padding: 20px 0 0 0;
        font-size: 28px;
        line-height: 70px;
        letter-spacing: 13px;
      }
    }
  }
  &.color-yorokobi-0-1{
    @media screen and (max-width: 750px) {
      width: pSP(86);
      margin: pSP(501) pSP(563) 0 0;
      // padding-bottom: pSP(416);
      padding-bottom: pSP(15);
      // background-image: url("../images/color-yorokobi-0-1-sp.jpg");
      .color-in{
        padding: vwSP(25) 0 0 0;
        font-size: vwSP(28);
        line-height: vwSP(90);
        letter-spacing: vwSP(7);
        -ms-writing-mode: tb-rl;
        writing-mode: vertical-rl;
        text-orientation: mixed;
      }
    }
    @media screen and (min-width: 751px) {
      width: pPC(55);
      margin: pPC(219) pPC(466) 0 0;
      // padding-bottom: pPC(246);
      padding-bottom: pPC(12);
      -ms-writing-mode: tb-rl;
      writing-mode: vertical-rl;
      text-orientation: mixed;
      // background-image: url("../images/color-yorokobi-0-1-pc.jpg");
    }
    @media screen and (min-width: 751px) and (max-width: 1200px) {
      .color-in{
        padding: vwPC(20) 0 0 0;
        font-size: vwPC(16);
        line-height: vwPC(58);
        letter-spacing: vwPC(4);
      }
    }
    @media screen and (min-width: 1201px) {
      .color-in{
        padding: 20px 0 0 0;
        font-size: 16px;
        line-height: 58px;
        letter-spacing: 4px;
      }
    }
  }
  &.color-yorokobi-1-0{
    @media screen and (max-width: 750px) {
      width: pSP(100);
      margin: pSP(0) pSP(100) 0 0;
      // padding-bottom: pSP(209);
      padding-bottom: pSP(15);
      // background-image: url("../images/color-yorokobi-1-0-sp.jpg");
      .color-in{
        padding: vwSP(25) 0 0 0;
        font-size: vwSP(44);
        line-height: vwSP(99);
        letter-spacing: vwSP(13);
        -ms-writing-mode: tb-rl;
        writing-mode: vertical-rl;
        text-orientation: mixed;
      }
    }
    @media screen and (min-width: 751px) {
      width: pPC(69);
      margin: pPC(403) pPC(876) 0 0;
      // padding-bottom: pPC(148);
      padding-bottom: pPC(12);
      -ms-writing-mode: tb-rl;
      writing-mode: vertical-rl;
      text-orientation: mixed;
      // background-image: url("../images/color-yorokobi-1-0-pc.jpg");
    }
    @media screen and (min-width: 751px) and (max-width: 1200px) {
      .color-in{
        padding: vwPC(20) 0 0 0;
        font-size: vwPC(28);
        line-height: vwPC(70);
        letter-spacing: vwPC(13);
      }
    }
    @media screen and (min-width: 1201px) {
      .color-in{
        padding: 20px 0 0 0;
        font-size: 28px;
        line-height: 70px;
        letter-spacing: 13px;
      }
    }
  }
  &.color-yorokobi-1-1{
    @media screen and (max-width: 750px) {
      width: pSP(85);
      margin: pSP(1794 - 1762) pSP(239) 0 0;
      // padding-bottom: pSP(364);
      padding-bottom: pSP(15);
      // background-image: url("../images/color-yorokobi-1-1-sp.jpg");
      .color-in{
        padding: vwSP(25) 0 0 0;
        font-size: vwSP(28);
        line-height: vwSP(90);
        letter-spacing: vwSP(7);
        -ms-writing-mode: tb-rl;
        writing-mode: vertical-rl;
        text-orientation: mixed;
      }
    }
    @media screen and (min-width: 751px) {
      width: pPC(55);
      margin: pPC(421) pPC(974) 0 0;
      // padding-bottom: pPC(214);
      padding-bottom: pPC(12);
      -ms-writing-mode: tb-rl;
      writing-mode: vertical-rl;
      text-orientation: mixed;
      // background-image: url("../images/color-yorokobi-1-1-pc.jpg");
    }
    @media screen and (min-width: 751px) and (max-width: 1200px) {
      .color-in{
        padding: vwPC(20) 0 0 0;
        font-size: vwPC(16);
        line-height: vwPC(58);
        letter-spacing: vwPC(4);
      }
    }
    @media screen and (min-width: 1201px) {
      .color-in{
        padding: 20px 0 0 0;
        font-size: 16px;
        line-height: 58px;
        letter-spacing: 4px;
      }
    }
  }
  &.color-gift-0-0{
    @media screen and (max-width: 750px) {
      right: auto;
      left: 0;
      width: pSP(538);
      margin: pSP(190) 0 0 pSP(0);
      // padding-bottom: pSP(102);
      padding-right: pSP(15);
      // background-image: url("../images/color-gift-0-0-sp.jpg");
      .color-in{
        padding: 0 0 0 vwSP(35);
        font-size: vwSP(44);
        line-height: vwSP(99);
        letter-spacing: vwSP(10);
      }
    }
    @media screen and (min-width: 751px) {
      right: auto;
      left: 0;
      width: pPC(65);
      margin: pPC(171) 0 0 pPC(165);
      // padding-bottom: pPC(342);
      padding-bottom: pPC(12);
      -ms-writing-mode: tb-rl;
      writing-mode: vertical-rl;
      text-orientation: mixed;
      // background-image: url("../images/color-gift-0-0-pc.jpg");
    }
    @media screen and (min-width: 751px) and (max-width: 1200px) {
      .color-in{
        padding: vwPC(20) 0 0 0;
        font-size: vwPC(28);
        line-height: vwPC(70);
        letter-spacing: vwPC(6.5);
      }
    }
    @media screen and (min-width: 1201px) {
      .color-in{
        padding: 20px 0 0 0;
        font-size: 28px;
        line-height: 70px;
        letter-spacing: 6.5px;
      }
    }
  }
  &.color-gift-0-1{
    right: auto;
    left: 0;
    @media screen and (max-width: 750px) {
      width: pSP(646);
      margin: pSP(337) 0 0 pSP(40);
      // padding-bottom: pSP(102);
      padding-right: pSP(15);
      // background-image: url("../images/color-gift-0-1-sp.jpg");
      .color-in{
        padding: 0 0 0 vwSP(25);
        font-size: vwSP(44);
        line-height: vwSP(99);
        letter-spacing: vwSP(9);
      }
    }
    @media screen and (min-width: 751px) {
      width: pPC(65);
      margin: pPC(202) 0 0 pPC(68);
      // padding-bottom: pPC(414);
      padding-bottom: pPC(12);
      -ms-writing-mode: tb-rl;
      writing-mode: vertical-rl;
      text-orientation: mixed;
      // background-image: url("../images/color-gift-0-1-pc.jpg");
    }
    @media screen and (min-width: 751px) and (max-width: 1200px) {
      .color-in{
        padding: vwPC(20) 0 0 0;
        font-size: vwPC(28);
        line-height: vwPC(70);
        letter-spacing: vwPC(6.5);
      }
    }
    @media screen and (min-width: 1201px) {
      .color-in{
        padding: 20px 0 0 0;
        font-size: 28px;
        line-height: 70px;
        letter-spacing: 6.5px;
      }
    }
  }
  @for $i from 1 through 3 {
    &:nth-of-type(#{$i}){
      &:before{
        .aos-animate &{
          transition: opacity 0.01s #{$i * 0.2s + 0.5 * $ani_s};
        }
      }
      .color-in{
        .aos-animate &{
          transition: opacity 0.01s #{$i * 0.2s + 0.5 * $ani_s};
        }
      }
      &:after{
        .aos-animate &{
          transition: transform $ani_s $i * 0.2s linear;
        }
      }
    }
  }
}
.fix-online-shop-btn{
  position: fixed;
  top: 0;
  right: 0;
  background-size: 100% 100%;
  @media screen and (max-width: 750px) {
    width: pSP(130);
    margin: 0 pSP(40) 0 0;
    padding-bottom: pSP(149);
    background-image: url("../images/online-shop-btn-sp.png");
  }
  @media screen and (min-width: 751px) {
    background-image: url("../images/online-shop-btn-pc.png");
  }
  @media screen and (min-width: 751px) and (max-width: 1600px) {
    width: calc( 100% * ( 120 / 1600 ) );
    margin: 0  calc( 100% * ( 140 / 1600 ) ) 0 0;
    padding-bottom: calc( 100% * ( 120 / 1600 ) );
  }
  @media screen and (min-width: 1601px) {
    width: 120px;
    margin: 0 140px 0 0;
    padding-bottom: 120px;
  }
}
