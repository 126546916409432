@charset "utf-8";
footer{
  position: relative;
  z-index: 1;
  background-color: #6fa1ab;
  @media screen and (max-width: 750px) {
    height: vwSP(630);
  }
  @media screen and (min-width: 751px) {
  }
  @media screen and (min-width: 751px) and (max-width: 1200px) {
    height: vwPC(255);
  }
  @media screen and (min-width: 1201px) {
    height: 255px;
  }
}
.footer-logo-TONoSORA{
  position: absolute;
  top: 0;
  left: 0;
  display: block;
  background-image: url("../images/logo-TONoSORA.png");
  background-repeat: no-repeat;
  background-size: 100% 100%;
  @media screen and (max-width: 750px) {
    right: 0;
    width: pSP(181);
    margin: pSP(100) auto 0;
    padding-bottom: pSP(219);
  }
  @media screen and (min-width: 751px) {
    width: pPC(126);
    margin: pPC(50) 0 0 0;
    padding-bottom: pPC(155);
  }
}
.sns-instragram{
  position: absolute;
  top: 0;
  display: block;
  background-image: url("../images/sns-instragram.png");
  background-repeat: no-repeat;
  background-size: 100% 100%;
  @media screen and (max-width: 750px) {
    right: 0;
    right: 0;
    left: 0;
    width: pSP(51);
    margin: pSP(408) auto 0;
    padding-bottom: pSP(51);
  }
  @media screen and (min-width: 751px) {
    right: 0;
    width: pPC(29);
    margin: pPC(104) 0 0 0;
    padding-bottom: pPC(29);
  }
}
.copyright-w{
  position: absolute;
  top: 0;
  font-family: 'Lato', sans-serif;
  line-height: 1;
  color: #fff;
  @media screen and (max-width: 750px) {
    left: 0;
    width: 100%;
    margin: pSP(507) 0 0 0;
    text-align: center;
  }
  @media screen and (min-width: 751px) {
    right: 0;
    width: 50%;
    margin: pPC(193) 0 0 0;
    text-align: right;
  }
}
